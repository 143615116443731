import Grid from '@mui/material/Unstable_Grid2/Grid2';
import TextField from '../TextField';
import { ApiParticipant } from '../../types/api/ApiParticipant';
import DatePickerField from '../DatePickerField';
import RadioField from '../RadioField';
import NumberField from '../NumberField';
import TextareaField from '../TextareaField';
import { Divider, Typography } from '@mui/material';
import DropdownField from '../DropdownField';

interface ParticipantResumeProps {
  participant: ApiParticipant;
}

export const ParticipantResume = ({ participant }: ParticipantResumeProps) => {
  const baseProps = {
    disabled: false,
    readOnly: true,
    onChange: () => {},
  };

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid xs={12}>University / College experience</Grid>
      {!participant.details['University_College-Name_Unversity_College1'] && (
        <Typography fontStyle="italic">No tertiary education experience listed</Typography>
      )}
      {!!participant.details['University_College-Name_Unversity_College1'] && (
        <>
          <Grid xs={12} lg={4}>
            <TextField
              {...baseProps}
              name="University_College-Name_Unversity_College1"
              label="Name of University / College"
              value={participant.details['University_College-Name_Unversity_College1'] || ''}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <DropdownField
              {...baseProps}
              label="Location (Country)"
              value={participant.details['University_College-University_Location_Country1'] || ''}
              name="University_College-University_Location_Country1"
              options={[participant.details['University_College-University_Location_Country1' || '']].map((o) => ({
                value: o!,
                label: o!,
              }))}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="University_College-Major1" label="Major" value={participant.details['University_College-Major1'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="University_College-Degree1" label="Degree" value={participant.details['University_College-Degree1'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField
              {...baseProps}
              name="University_College-Graduation_date1"
              label="Graduation date (must match date on diploma)"
              value={participant.details['University_College-Graduation_date1']}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField
              {...baseProps}
              name="University_College-Date_of_initial_enrollment1"
              label="Date of initial enrollment (Matriculation)"
              value={participant.details['University_College-Date_of_initial_enrollment1']}
            />
          </Grid>
          <Grid xs={12}>
            <RadioField
              {...baseProps}
              label="Status of degree"
              value={participant.details['University_College-Status_of_degree1']}
              name="University_College-Status_of_degree1"
              options={['Earned', 'In Progress', 'Incomplete', 'On Hold'].map((o) => ({ value: o, label: o }))}
              direction="horizontal"
            />
          </Grid>
        </>
      )}
      {!!participant.details['University_College-Name_Unversity_College2'] && (
        <>
          <Grid xs={12} lg={4}>
            <TextField
              {...baseProps}
              name="University_College-Name_Unversity_College2"
              label="Name of University / College"
              value={participant.details['University_College-Name_Unversity_College2'] || ''}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <DropdownField
              {...baseProps}
              label="Location (Country)"
              value={participant.details['University_College-University_Location_Country2'] || ''}
              name="University_College-University_Location_Country2"
              options={[participant.details['University_College-University_Location_Country2' || '']].map((o) => ({
                value: o!,
                label: o!,
              }))}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="University_College-Major2" label="Major" value={participant.details['University_College-Major2'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="University_College-Degree2" label="Degree" value={participant.details['University_College-Degree2'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField
              {...baseProps}
              name="University_College-Graduation_date2"
              label="Graduation date (must match date on diploma)"
              value={participant.details['University_College-Graduation_date2']}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField
              {...baseProps}
              name="University_College-Date_of_initial_enrollment2"
              label="Date of initial enrollment (Matriculation)"
              value={participant.details['University_College-Date_of_initial_enrollment2']}
            />
          </Grid>
          <Grid xs={12}>
            <RadioField
              {...baseProps}
              label="Status of degree"
              value={participant.details['University_College-Status_of_degree2']}
              name="University_College-Status_of_degree2"
              options={['Earned', 'In Progress', 'Incomplete', 'On Hold'].map((o) => ({ value: o, label: o }))}
              direction="horizontal"
            />
          </Grid>
        </>
      )}
      {!!participant.details['University_College-Name_Unversity_College3'] && (
        <>
          <Grid xs={12} lg={4}>
            <TextField
              {...baseProps}
              name="University_College-Name_Unversity_College3"
              label="Name of University / College"
              value={participant.details['University_College-Name_Unversity_College3'] || ''}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <DropdownField
              {...baseProps}
              label="Location (Country)"
              value={participant.details['University_College-University_Location_Country3'] || ''}
              name="University_College-University_Location_Country3"
              options={[participant.details['University_College-University_Location_Country3' || '']].map((o) => ({
                value: o!,
                label: o!,
              }))}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="University_College-Major3" label="Major" value={participant.details['University_College-Major3'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="University_College-Degree3" label="Degree" value={participant.details['University_College-Degree3'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField
              {...baseProps}
              name="University_College-Graduation_date3"
              label="Graduation date (must match date on diploma)"
              value={participant.details['University_College-Graduation_date3']}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField
              {...baseProps}
              name="University_College-Date_of_initial_enrollment3"
              label="Date of initial enrollment (Matriculation)"
              value={participant.details['University_College-Date_of_initial_enrollment3']}
            />
          </Grid>
          <Grid xs={12}>
            <RadioField
              {...baseProps}
              label="Status of degree"
              value={participant.details['University_College-Status_of_degree3']}
              name="University_College-Status_of_degree3"
              options={['Earned', 'In Progress', 'Incomplete', 'On Hold'].map((o) => ({ value: o, label: o }))}
              direction="horizontal"
            />
          </Grid>
        </>
      )}
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid xs={12}>Professional experience</Grid>
      {!participant.details['Business-Name_of1'] && <Typography fontStyle="italic">No professional experience listed</Typography>}
      {!!participant.details['Business-Name_of1'] && (
        <>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="Business-Name_of1" label="Business name" value={participant.details['Business-Name_of1'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DropdownField
              {...baseProps}
              label="Location (Country)"
              value={participant.details['Business-Business_Location_Country1'] || ''}
              name="Business-Business_Location_Country1"
              options={[participant.details['Business-Business_Location_Country1' || '']].map((o) => ({
                value: o!,
                label: o!,
              }))}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            {/* push */}
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="Business-Title1" label="Title of position" value={participant.details['Business-Title1'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField {...baseProps} name="Business-Start_Date1" label="Start date" value={participant.details['Business-Start_Date1']} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField {...baseProps} name="Business-End_Date1" label="End date" value={participant.details['Business-End_Date1']} />
          </Grid>
          <Grid xs={12} lg={6} container>
            <Grid xs={12}>
              <NumberField
                {...baseProps}
                name="Business-Average_hours_per_week1"
                label="Average hours per week"
                value={participant.details['Business-Average_hours_per_week1'] || ''}
              />
            </Grid>
            <Grid xs={12}>
              <RadioField
                {...baseProps}
                label="Employment status"
                value={participant.details['Business-Employment_status1']}
                name="Business-Employment_status1"
                options={['Part Time', 'Full Time'].map((o) => ({ value: o, label: o }))}
                direction="horizontal"
              />
            </Grid>
          </Grid>
          <Grid xs={12} lg={6}>
            <TextareaField
              {...baseProps}
              name="Business-Description_of_Duties1"
              label="Description of duties"
              value={participant.details['Business-Description_of_Duties1'] || ''}
            />
          </Grid>
        </>
      )}
      {!!participant.details['Business-Name_of2'] && (
        <>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="Business-Name_of2" label="Business name" value={participant.details['Business-Name_of2'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DropdownField
              {...baseProps}
              label="Location (Country)"
              value={participant.details['Business-Business_Location_Country2'] || ''}
              name="Business-Business_Location_Country2"
              options={[participant.details['Business-Business_Location_Country2' || '']].map((o) => ({
                value: o!,
                label: o!,
              }))}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            {/* push */}
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="Business-Title2" label="Title of position" value={participant.details['Business-Title2'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField {...baseProps} name="Business-Start_Date2" label="Start date" value={participant.details['Business-Start_Date2']} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField {...baseProps} name="Business-End_Date2" label="End date" value={participant.details['Business-End_Date2']} />
          </Grid>
          <Grid xs={12} lg={6} container>
            <Grid xs={12}>
              <NumberField
                {...baseProps}
                name="Business-Average_hours_per_week2"
                label="Average hours per week"
                value={participant.details['Business-Average_hours_per_week2'] || ''}
              />
            </Grid>
            <Grid xs={12}>
              <RadioField
                {...baseProps}
                label="Employment status"
                value={participant.details['Business-Employment_status2']}
                name="Business-Employment_status2"
                options={['Part Time', 'Full Time'].map((o) => ({ value: o, label: o }))}
                direction="horizontal"
              />
            </Grid>
          </Grid>
          <Grid xs={12} lg={6}>
            <TextareaField
              {...baseProps}
              name="Business-Description_of_Duties2"
              label="Description of duties"
              value={participant.details['Business-Description_of_Duties2'] || ''}
            />
          </Grid>
        </>
      )}
      {!!participant.details['Business-Name_of3'] && (
        <>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="Business-Name_of3" label="Business name" value={participant.details['Business-Name_of3'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DropdownField
              {...baseProps}
              label="Location (Country)"
              value={participant.details['Business-Business_Location_Country3'] || ''}
              name="Business-Business_Location_Country3"
              options={[participant.details['Business-Business_Location_Country3' || '']].map((o) => ({
                value: o!,
                label: o!,
              }))}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            {/* push */}
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField {...baseProps} name="Business-Title3" label="Title of position" value={participant.details['Business-Title3'] || ''} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField {...baseProps} name="Business-Start_Date3" label="Start date" value={participant.details['Business-Start_Date3']} />
          </Grid>
          <Grid xs={12} lg={4}>
            <DatePickerField {...baseProps} name="Business-End_Date3" label="End date" value={participant.details['Business-End_Date3']} />
          </Grid>
          <Grid xs={12} lg={6} container>
            <Grid xs={12}>
              <NumberField
                {...baseProps}
                name="Business-Average_hours_per_week3"
                label="Average hours per week"
                value={participant.details['Business-Average_hours_per_week3'] || ''}
              />
            </Grid>
            <Grid xs={12}>
              <RadioField
                {...baseProps}
                label="Employment status"
                value={participant.details['Business-Employment_status3']}
                name="Business-Employment_status3"
                options={['Part Time', 'Full Time'].map((o) => ({ value: o, label: o }))}
                direction="horizontal"
              />
            </Grid>
          </Grid>
          <Grid xs={12} lg={6}>
            <TextareaField
              {...baseProps}
              name="Business-Description_of_Duties3"
              label="Description of duties"
              value={participant.details['Business-Description_of_Duties3'] || ''}
            />
          </Grid>
        </>
      )}
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid xs={12}>
        <DropdownField
          {...baseProps}
          label="Total years of work experience"
          value={participant.details['Resumes-Years_of_experience'] || ''}
          name="Resumes-Years_of_experience"
          options={['1 year', '2 years', '3 years', '4 years', '5 years', '6 years', '7 years', '8 years', '9 years', '10 or more years'].map((o) => ({
            value: o,
            label: o,
          }))}
        />
      </Grid>
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid xs={12}>Skills</Grid>
      <Grid xs={12}>
        <TextareaField {...baseProps} label="" value={participant.details['Resumes-Skills'] || ''} name="Resumes-Skills" />
      </Grid>
    </Grid>
  );
};
