import { Navigate, useParams } from 'react-router-dom';
import { CreateApplicant } from 'applicants/CreateApplicant';
import { Applicants } from 'applicants/Applicants';
import { UserRole } from 'permissions/constants/Roles';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { HandleFetch } from 'components/LoadingOrError/HandleFetch';
import { usePermissions } from 'contexts/PermissionContext';

export const StartApplication = () => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const { hasRole } = usePermissions();
  const sourceUrl = `/schools/${schoolSlug}/applicants`;

  const showApplicants = (response) => {
    if (hasRole([UserRole.Applicant])) {
      return <Navigate to={`/${schoolSlug}/enrolment-application/applicant/${response.data[0].id}`} />;
    }

    return <Applicants applicants={response.data} />;
  };

  return (
    <HandleFetch small sourceUrl={sourceUrl}>
      {(response) => {
        return response.data.length ? showApplicants(response) : <CreateApplicant />;
      }}
    </HandleFetch>
  );
};
