import * as types from 'app/constants/ActionTypes';

export const valuesInitialState = {
  name: 'Enroller',
  color: '#3ead47',
  slug: '',
  logo: '/images/icons/favicon-120.png',
};

export const valuesReducer = (state = valuesInitialState, action) => {
  switch (action.type) {
    case types.AGENTS_ADD_RESPONSE: {
      const resetAgentsForm = { ...state };

      if (action.payload.success) {
        Object.keys(state).forEach((fieldName) => {
          if (fieldName.match('agents_add_')) {
            resetAgentsForm[fieldName] = '';
          }
        });
      }

      return {
        ...state,
        ...resetAgentsForm,
      };
    }
    case types.NOTES_ADD_RESPONSE: {
      const resetAddNoteForm = { ...state };

      if (action.payload.success) {
        Object.keys(resetAddNoteForm).forEach((fieldName) => {
          if (fieldName.match('notes_add_')) {
            delete resetAddNoteForm[fieldName];
          }
        });
      }

      return {
        ...resetAddNoteForm,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
