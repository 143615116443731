import { useState, MouseEventHandler, useLayoutEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { AuthUser } from 'utils/user';
import HasAccess from 'permissions/HasAccess';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import { ProductsAccess } from 'permissions/ProductsAccess';
import { ProductLevel } from 'permissions/constants/ProductLevels';
import { greenheartStaffRoles, UserRole } from 'permissions/constants/Roles';
import { useLocation } from 'react-router-dom';
import { useSchool } from '../../contexts/SchoolContext';
import { getConfig } from 'utils/config';

export interface ProfileMenuProps {
  hideLogin?: boolean;
}

export const ProfileMenu = ({ hideLogin = false }: ProfileMenuProps) => {
  const { user, isAuthenticated, logout } = useAuth0<AuthUser>();
  const {
    state: { school },
  } = useSchool();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);
  const theme = useTheme();
  const location = useLocation();
  const { agenciesUrl } = getConfig();

  useLayoutEffect(() => setAnchorEl(null), [location.pathname]);

  const onProfileAvatarClick: MouseEventHandler<HTMLButtonElement> = (event) => setAnchorEl(event.currentTarget);
  const onClose: MouseEventHandler<HTMLElement> = () => setAnchorEl(null);
  const onLogoutClick: MouseEventHandler<HTMLLIElement> = (event) => {
    onClose(event);
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  if (hideLogin) {
    return <></>;
  }
  if (!isAuthenticated) {
    return <Link href={`/login`}>Login</Link>;
  }

  return (
    <>
      <Tooltip title="Profile Menu">
        <IconButton onClick={onProfileAvatarClick} data-cy="profile-card">
          <AccountCircleOutlinedIcon style={{ width: '32px', height: '32px', color: theme.palette.menuColor }} />
        </IconButton>
      </Tooltip>
      {isMenuOpen && (
        <Menu
          anchorEl={anchorEl}
          open={true}
          onClose={onClose}
          sx={{ p: 2 }}
          slotProps={{ paper: { sx: { width: '268px' } } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <HasAccess
            // Hide for guest users
            for="role"
            name={[
              UserRole.Applicant,
              UserRole.Agent,
              UserRole.Parent,
              UserRole.InstitutionalReadOnlyStaff,
              UserRole.InstitutionalStaff,
              UserRole.InstitutionalAdmin,
              UserRole.HostOrganizationStaff,
              UserRole.HostOrganizationAdmin,
              UserRole.PartnerStaff,
              UserRole.PartnerAdmin,
              ...greenheartStaffRoles,
            ]}
          >
            <MenuItem {...{ component: Link }} href="/profile" onClick={onClose}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <AccountCircleOutlinedIcon />
                {user !== undefined && (
                  <Stack>
                    <Typography variant="subtitle1" fontSize="16px" color="black" fontWeight="700">
                      {user.userData.firstName} {user?.userData.lastName}
                    </Typography>
                    <Typography variant="subtitle2" fontSize="14px" color="#414141">
                      {user.email}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </MenuItem>
            <Divider component="li" />
          </HasAccess>

          <HasAccess for="role" name={[UserRole.PartnerAdmin, UserRole.HostOrganizationAdmin]}>
            <MenuItem href={agenciesUrl} {...{ component: Link }} onClick={onClose}>
              <ListItemIcon>
                <KeyOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Manage User Access</ListItemText>
            </MenuItem>
          </HasAccess>

          {school?.slug && (
            <HasAccess for="role" name={[UserRole.InstitutionalAdmin]}>
              <ProductsAccess disallow={[ProductLevel.EnrollerRecruit]}>
                <MenuItem href={`/${school.slug}/settings/permissions`} {...{ component: Link }} onClick={onClose}>
                  <ListItemIcon>
                    <PeopleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>User Settings</ListItemText>
                </MenuItem>
              </ProductsAccess>
              <MenuItem href={`/${school.slug}/settings/school`} {...{ component: Link }} onClick={onClose}>
                <ListItemIcon>
                  <SchoolOutlinedIcon />
                </ListItemIcon>
                <ListItemText>School Settings</ListItemText>
              </MenuItem>
              <MenuItem href={`/${school.slug}/settings/application`} {...{ component: Link }} onClick={onClose}>
                <ListItemIcon>
                  <ArticleOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Application Settings</ListItemText>
              </MenuItem>
              <ProductsAccess disallow={[ProductLevel.EnrollerRecruit]}>
                <MenuItem href={`/${school.slug}/settings/financial`} {...{ component: Link }} onClick={onClose}>
                  <ListItemIcon>
                    <LocalAtmOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Financial Settings</ListItemText>
                </MenuItem>
              </ProductsAccess>
              <Divider component="li" />
            </HasAccess>
          )}
          <MenuItem onClick={onLogoutClick}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
