import { ApplicantEnrolmentsHeader } from './ApplicantEnrolmentsHeader';
import { useEffect, useMemo, useState } from 'react';
import { ResponseEnvelope } from '../../types/ResponseEnvelope';
import { useGet } from '../../hooks/useGet';
import { useParams } from 'react-router-dom';
import { SchoolWorkflowRouteParams } from '../../app/routes/SchoolWorkflowRoutes';
import { useApplicant } from '../../contexts/ApplicantContext';
import { get } from 'lodash';
import { usePageTitle } from '../../contexts/PageTitleContext';
import { LinearProgress } from '@mui/material';
import HasAccess from '../../permissions/HasAccess';
import { Permission } from '../../permissions/constants/Roles';
import { PrivateRoute } from '../../app/PrivateRoute';
import { FilteredApplicantPipeline } from '../../applicants/FilteredApplicantPipeline';
import { useSubHeader } from '../AppLayout/AppLayout';
import { useSchool } from 'contexts/SchoolContext';

const TABS = [
  {
    label: 'In progress',
    counter: 'total_in_progress',
    filters: [
      {
        name: 'archived',
        value: '0',
      },
      {
        name: 'status',
        value: '',
      },
    ],
  },
  {
    label: 'Enrolled',
    counter: 'total_enrolled',
    filters: [
      {
        name: 'archived',
        value: '1',
      },
      {
        name: 'status',
        value: 'enrolled',
      },
    ],
  },
  {
    label: 'Withdrawn',
    counter: 'total_withdrawn',
    filters: [
      {
        name: 'archived',
        value: '1',
      },
      {
        name: 'status',
        value: 'withdrawn',
      },
    ],
  },
];

const getFiltersFromTab = (tab: number) =>
  TABS[tab].filters.reduce((tabFilters, filter) => {
    tabFilters[filter.name] = filter.value;
    return tabFilters;
  }, {});

export const ApplicantEnrolmentsLayout = () => {
  const { slug: schoolSlug, applicant: applicantId } = useParams() as SchoolWorkflowRouteParams;
  const {
    state: { applicant, status },
  } = useApplicant();
  const { setPageTitle } = usePageTitle();
  const { setSubHeader } = useSubHeader();
  const {
    state: { school },
  } = useSchool();
  const schoolName = school?.name;
  const [activeTab, setActiveTab] = useState(0);
  const [applicationData, setApplicationData] = useState<ResponseEnvelope<any>>();
  const [filters, setFilters] = useState<Record<string, string>>(getFiltersFromTab(0));
  const [loading, getApplications] = useGet<any>(`/schools/${schoolSlug}/applicants/${applicantId || applicant?.id}/applications`);

  useEffect(() => {
    if (applicant) {
      getApplications(new URLSearchParams(filters)).then((response) => {
        setApplicationData(response);
      });
    }
  }, [applicant, filters, getApplications]);

  useEffect(() => {
    setFilters(getFiltersFromTab(activeTab));
  }, [activeTab]);

  const pipelineTabs = useMemo(
    () =>
      TABS.map((tab) => ({
        ...tab,
        counter: get(applicationData, tab.counter, 0),
      })),
    [applicationData],
  );
  let displayName = 'Loading';

  if (applicant) {
    if (applicant.middle_name) {
      displayName = `${applicant.first_name} ${applicant.middle_name} ${applicant.last_name}`;
    } else {
      displayName = `${applicant.first_name} ${applicant.last_name}`;
    }
  }

  useEffect(() => {
    if (schoolName && displayName) {
      setPageTitle(`${schoolName} | ${displayName || 'New Applicant'}`);
    }
  }, [setPageTitle, schoolName, displayName]);

  useEffect(() => {
    setSubHeader(<ApplicantEnrolmentsHeader {...{ activeTab, setActiveTab, pipelineTabs }} />);
  }, [activeTab, pipelineTabs, setSubHeader]);

  if (status === 'pending' || !applicant) return <LinearProgress variant="indeterminate" />;

  return (
    <HasAccess for="permission" name={[Permission.ViewPipeline]}>
      <PrivateRoute>{status === 'success' && <FilteredApplicantPipeline {...{ filters, applicationData, loading }} />}</PrivateRoute>
    </HasAccess>
  );
};
