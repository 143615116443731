import { useState } from 'react';
import { Link } from 'react-router-dom';
import Country from 'components/Country/Country';
import { formatDistanceToNow } from 'date-fns';
import { useMountEffect } from 'hooks/useMountEffect';
import { usePageTitle } from 'contexts/PageTitleContext';
import { Box, Stack } from '@mui/material';
import { MRT_ColumnDef, MRT_RowData } from 'material-react-table';
import { useStatelessGet } from '../../hooks/useStatelessGet';
import { PaginatedEnvelope } from '../../types/PaginatedEnvelope';
import { useDataTable } from '../../hooks/useDataTable';
import { DataTable } from '../../components/DataTable/DataTable';

const columns: MRT_ColumnDef<MRT_RowData>[] = [
  {
    header: 'Nationality',
    Cell: ({ row }) => (
      <Stack direction="row">
        <Country name={row.original.nationality_name} code={row.original.nationality_iso} hideCode />
        <span>{row.original.nationality_name}</span>
      </Stack>
    ),
  },
  {
    header: 'Name',
    accessorFn: (originalRow) => `${originalRow.first_name} ${originalRow.last_name}`,
    Cell: ({ row }) => (
      <Link
        state={{ user: `${row.original.first_name} ${row.original.last_name}` }}
        to={`/${row.original.school_slug}/enrolment-application/applicant/${row.original.applicant_id}/application/${row.original.id}/stages`}
      >
        {row.original.first_name} {row.original.last_name}
      </Link>
    ),
  },
  {
    header: 'Email',
    accessorKey: 'email',
    Cell: ({ row }) => (
      <a href={`mailto: ${row.original.email}`} target="_blank" rel="noopener noreferrer">
        {row.original.email}
      </a>
    ),
  },
  {
    header: 'Provider',
    accessorKey: 'school_name',
    Cell: ({ row }) => <Stack direction="row">{row.original.school_name}</Stack>,
  },
  {
    header: 'Course',
    accessorKey: 'course',
  },
  {
    header: 'Status',
    accessorKey: 'step_description',
  },
  {
    header: 'Last Updated',
    accessorKey: 'updated_at',
    Cell: ({ row }) => (
      <>
        {formatDistanceToNow(new Date(row.original.updated_at), {
          addSuffix: true,
        })}
      </>
    ),
  },
];

export const SharedEnrolments = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const { setPageTitle } = usePageTitle();
  const getApplications = useStatelessGet<PaginatedEnvelope<any>>('/applications');

  useMountEffect(() => setPageTitle('Shared Enrolments'));

  const { table } = useDataTable<MRT_RowData>({
    tableKey: 'sharedEnrolments',
    getTableData: getApplications,
    columnDefinitions: columns,
    globalFilter,
  });

  const tableEntityName = {
    singular: 'application',
    plural: 'applications',
  };

  return (
    <Box p={2}>
      <DataTable table={table} tableHeading="Your Enrolments" globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} entityName={tableEntityName} />
    </Box>
  );
};
