import { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { MenuLink } from 'components/MenuLink/MenuLink';
import { RemindersDialog } from 'enquiries/reminders/RemindersDialog';
import { MobileMenu } from 'components/MobileMenu/MobileMenu';
import HasAccess from 'permissions/HasAccess';
import { ProductsAccess } from 'permissions/ProductsAccess';
import { mainNav } from 'config/routes';
import { reminders } from 'enquiries/enquiriesSelector';
import { Badge, IconButton, Skeleton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import { slugify } from 'utils/slugify';
import { ProfileMenu } from 'components/Menu/ProfileMenu';
import { LocaleMenu } from 'components/Menu/LocaleMenu';
import { Permission } from 'permissions/constants/Roles';
import { isMenuItemActive } from '../../utils/isMenuItemActive';
import { useSchool } from '../../contexts/SchoolContext';

const MainNavItem = ({ nav, index }) => {
  const {
    state: { school },
  } = useSchool();

  if (!school && nav.requiresSchool)
    return (
      <HasAccess key={`item-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
        <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
          <Skeleton variant="text" />
        </ProductsAccess>
      </HasAccess>
    );

  const isRoot = nav.url[0] === '/';
  let url = nav.url;
  if (!isRoot && nav.requiresSchool) {
    url = `/${school!.slug}/${nav.url}`;
  }
  url = nav.external ? nav.url : url;

  return (
    <HasAccess key={`item-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
      <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
        <MenuLink to={url} navChildren={nav.children} label={nav.title} external={nav.external} active={isMenuItemActive(nav.url)} titleIcon={nav.titleIcon} />
      </ProductsAccess>
    </HasAccess>
  );
};

const MainNavMenu = ({ items }) => {
  const theme = useTheme();
  const hideMainMenu = useMediaQuery(theme.breakpoints.down('lg'));

  if (hideMainMenu) return <></>;

  return items.map((nav, index: number) => {
    if (nav.title === 'Profile') {
      return (
        <HasAccess key={`item-${index}`} for={nav.allowedFor} name={nav.allowedRoleTypes}>
          <ProductsAccess allow={nav.allowedProducts} disallow={nav.disallowedProducts}>
            <ProfileMenu />
          </ProductsAccess>
        </HasAccess>
      );
    }

    return <MainNavItem key={slugify(nav.title)} nav={nav} index={index} />;
  });
};

export const Menu = () => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const {
    state: { school },
  } = useSchool();
  const totalReminders = useAppSelector((state) => reminders(state, {}).length);

  const handleNotificationToggle = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const mainNavItems = mainNav.filter((nav) => {
    if (nav.hide) return false;

    const isRoot = nav.url[0] === '/';

    if (!isRoot) {
      if (nav.requiresSchool && !school?.slug && nav.title !== 'Profile' && !nav.external) {
        return false;
      }
    }

    return true;
  });
  const leftMainNavItems = mainNavItems.filter((nav) => !nav.alignRight);
  const rightMainNavItems = mainNavItems.filter((nav) => nav.alignRight);

  const renderNotificationBadge = () => {
    if (typeof window === 'undefined' || totalReminders <= 1) {
      return <></>;
    }

    return [
      <Tooltip key={0} title="Notifications">
        <IconButton onClick={handleNotificationToggle}>
          <Badge badgeContent={totalReminders}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>,
      <RemindersDialog key={1} active={isNotificationsOpen} onToggle={handleNotificationToggle} />,
    ];
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
      <Stack flex={1} direction="row" alignItems="center" justifyContent="flex-start" spacing={4} mr={3}>
        <MainNavMenu items={leftMainNavItems} />
      </Stack>
      <Stack flex={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
        {renderNotificationBadge()}
        <MainNavMenu items={rightMainNavItems} />
        <HasAccess for="permission" name={[Permission.SelectLanguage]}>
          <LocaleMenu />
        </HasAccess>
      </Stack>
      <MobileMenu
        mainNav={mainNav}
        localeChooser={
          <HasAccess for="permission" name={[Permission.SelectLanguage]}>
            <LocaleMenu />
          </HasAccess>
        }
      />
    </Stack>
  );
};
