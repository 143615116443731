// todo: Add in Zoho Deal Placement status types @see https://enroller.atlassian.net/browse/ER-4936

export const STUDENT_STATUS_IMPENDING = 'Impending';
export const STUDENT_STATUS_CURRENT = 'Current';
export const STUDENT_STATUS_ALUMNI = 'Alumni';
export const STUDENT_STATUS_ALL = 'All';

export const STUDENT_STATUSES = [STUDENT_STATUS_ALL, STUDENT_STATUS_ALUMNI, STUDENT_STATUS_CURRENT, STUDENT_STATUS_IMPENDING] as const;

export type StudentStatus = (typeof STUDENT_STATUSES)[number];

export function getStudentStatusColor(studentStatus: string) {
  // This is to handle all 23 different Zoho Deal Placement statuses...

  return STUDENT_STATUSES_COLORS[studentStatus] ? STUDENT_STATUSES_COLORS[studentStatus] : '#2CA74F';
}

export const STUDENT_STATUSES_COLORS = {
  '': 'none',
  [STUDENT_STATUS_ALL]: '#000000',
  [STUDENT_STATUS_ALUMNI]: '#00B2FF',
  [STUDENT_STATUS_CURRENT]: '#2CA74F',
  [STUDENT_STATUS_IMPENDING]: '#FFC600',
};

export const STUDENT_STATUSES_TEXT = {
  [STUDENT_STATUS_ALL]: 'All Enrolled',
  [STUDENT_STATUS_ALUMNI]: 'Alumni',
  [STUDENT_STATUS_CURRENT]: 'Current',
  [STUDENT_STATUS_IMPENDING]: 'Awaiting',
};
