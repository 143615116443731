import { useApplication } from '../../../../contexts/ApplicationContext';
import { useGet } from '../../../../hooks/useGet';
import { Skeleton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { PaginatedEnvelope } from '../../../../types/PaginatedEnvelope';
import { useNotificationMessages } from '../../../../hooks/useNotificationMessages';
import { getErrorMessage } from '../../../../utils/errors';
import { ScheduleInterviewParticipant } from './ScheduleInterviewParticipant';
import { ApiParticipant } from '../../types/api/ApiParticipant';

interface ScheduleInterviewFieldProps {
  fieldId: string;
}

export const ScheduleInterviewField = ({ fieldId }: ScheduleInterviewFieldProps) => {
  const {
    state: { application },
  } = useApplication();
  const getParticipantsUrl = `/interview/applications/${application?.id}/fields/${fieldId}/participants`;
  const sendInterviewDetailsUrl = `/interview/applications/${application?.id}/fields/${fieldId}/interview`;
  const acceptParticipantUrl = `/interview/applications/${application?.id}/fields/${fieldId}/accept`;
  const [loadingParticipants, getParticipants] = useGet<PaginatedEnvelope<ApiParticipant>>(getParticipantsUrl);
  const [participants, setParticipants] = useState<ApiParticipant[]>([]);
  const { showErrorMessage } = useNotificationMessages();

  const fetchParticipants = useCallback(async () => {
    try {
      const response = await getParticipants();
      setParticipants(response.data);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getParticipants, showErrorMessage]);

  useEffect(() => {
    fetchParticipants();
  }, [fetchParticipants]);

  if (!application || loadingParticipants) return <Skeleton variant="rectangular" />;

  if (!participants.length) return <Typography>No participants available</Typography>;

  return (
    <>
      {participants.map((participant) => (
        <ScheduleInterviewParticipant
          key={participant.id}
          participant={participant}
          sendInterviewDetailsUrl={sendInterviewDetailsUrl}
          acceptParticipantUrl={acceptParticipantUrl}
        />
      ))}
    </>
  );
};
