import { Permission, UserRole } from 'permissions/constants/Roles';
import React, { useCallback, useEffect, useState } from 'react';
import { ApplicantProvider } from 'contexts/ApplicantContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import CreateApplicant from 'applicants/CreateApplicant';
import { Workflow } from 'components/Workflow/Workflow';
import { ApplicationProvider } from 'contexts/ApplicationContext';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import Loader from 'components/Loader';
import { Application } from 'types/Application';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { usePermissions } from 'contexts/PermissionContext';

const Pipeline = React.lazy(() => import(/* webpackChunkName: 'pipeline' */ 'views/Pipeline/Pipeline'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: 'errorpage' */ 'views/Error/ErrorPage'));

export const ApplicantWorkflowRoutes = () => {
  const { slug } = useParams() as SchoolRouteParams;
  const { showErrorMessage } = useNotificationMessages();
  const { hasPermission, hasRole } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [applicantId, setApplicantId] = useState<string | undefined>(undefined);
  const [applicationId, setApplicationId] = useState<string | undefined>(undefined);
  // when you're logged in as an applicant this endpoint only sends back your application
  const getApplications = useStatelessGet<ResponseEnvelope<Application[]>>(`/schools/${slug}/applications`);
  const canViewPipeline = hasPermission([Permission.ViewPipeline]);
  const isApplicant = hasRole([UserRole.Applicant]);
  const navigate = useNavigate();

  const fetchApplicantData = useCallback(async () => {
    try {
      const { data: applications } = await getApplications();
      setApplicationId(applications?.[0]?.id);
      setApplicantId(applications?.[0]?.applicant_id);
    } catch (error) {
      showErrorMessage('Could not load applicant data');
      console.error(error);
    }

    setLoading(false);
  }, [getApplications, showErrorMessage]);

  // TODO: remove this call and load applicant from url segment, most likely redirect and unify inst and non-inst routes
  useEffect(() => {
    if (loading) {
      if (canViewPipeline && isApplicant) {
        fetchApplicantData();
      } else {
        setLoading(false);
      }
    }
  }, [loading, canViewPipeline, isApplicant, fetchApplicantData]);

  useEffect(() => {
    if (isApplicant && applicantId && applicationId) {
      navigate(`/${slug}/enrolment-application/applicant/${applicantId}/application/${applicationId}`);
    }
  }, [applicantId, applicationId, isApplicant, slug, navigate]);

  if (loading) {
    return <Loader />;
  }

  if (!canViewPipeline) {
    return <ErrorPage />;
  }

  if (!isApplicant) {
    return <Pipeline />;
  }

  if (!applicantId) {
    return <CreateApplicant />;
  }

  return (
    <ApplicantProvider id={applicantId}>
      <ApplicationProvider id={applicationId}>
        <Workflow />
      </ApplicationProvider>
    </ApplicantProvider>
  );
};
