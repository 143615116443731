import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import Content from 'components/Translation/Content';
import getApplicationStatus from 'utils/getApplicationStatus';

const ApplicationLink = styled(Link)`
  color: ${(props) => props.theme.tundora};

  &:hover {
    color: ${(props) => props.theme.tundora};
  }
`;

export const TicketCourse = ({ permanence, application, action, delay, course, isShared }) => {
  const { slug } = useParams();

  if (!application) {
    return <></>;
  }

  const applicationStatus = getApplicationStatus(permanence);

  return (
    <ApplicationLink
      title={`${action} (${applicationStatus.label})`}
      to={`/${slug}/enrolment-application/applicant/${application.applicant_id}/application/${application.id}`}
      className="ticket animated animated--onload anim__slide--up"
      style={{ animationDelay: delay }}
    >
      <div className="ticket__card">
        <div className="ticket__content" dangerouslySetInnerHTML={{ __html: course }} />
        <div className="ticket__status" style={{ backgroundColor: applicationStatus.color }} />
        <div className="ticket__details">
          <small className="ticket__action animated animated--onload animated--delayed6 anim__slide--up">
            <Content>
              {isShared ? 'Shared: ' : ''}
              {action}
            </Content>
          </small>
          <small className="ticket__permanence animated animated--onload animated--delayed7 anim__slide--up">{applicationStatus.label}</small>
        </div>
      </div>
    </ApplicationLink>
  );
};
