export enum UserRole {
  EnrollerAdmin = 'enroller-admin',
  Applicant = 'applicant',
  Agent = 'agent',
  Parent = 'parent',
  InstitutionalReadOnlyStaff = 'institutional-read-only-staff',
  InstitutionalStaff = 'institutional-staff',
  InstitutionalAdmin = 'institutional-admin',
  InstitutionalMarketingOnlyStaff = 'institutional-marketing-only-staff',
  SharedApplicant = 'shared-applicant',
  GreenheartAdmin = 'greenheart-admin',
  GreenheartEnrolmentStaff = 'greenheart-enrolment-staff',
  GreenheartEnrolmentAdmin = 'greenheart-enrolment-admin',
  GreenheartComplianceStaff = 'greenheart-compliance-staff',
  GreenheartComplianceAdmin = 'greenheart-compliance-admin',
  GreenheartAccountManagementStaff = 'greenheart-account-management-staff',
  GreenheartAccountManagementAdmin = 'greenheart-account-management-admin',
  GreenheartOnProgramStaff = 'greenheart-on-program-staff',
  GreenheartOnProgramAdmin = 'greenheart-on-program-admin',
  HostOrganizationStaff = 'host-org-staff',
  HostOrganizationAdmin = 'host-org-admin',
  PartnerStaff = 'partner-staff',
  PartnerAdmin = 'partner-admin',
  AgentAgencyAdmin = 'agent-agency-admin',
}

export const greenheartStaffRoles = [
  UserRole.GreenheartAdmin,
  UserRole.GreenheartEnrolmentAdmin,
  UserRole.GreenheartComplianceStaff,
  UserRole.GreenheartOnProgramStaff,
  UserRole.GreenheartAccountManagementAdmin,
  UserRole.GreenheartComplianceAdmin,
  UserRole.GreenheartAccountManagementStaff,
  UserRole.GreenheartEnrolmentStaff,
  UserRole.GreenheartOnProgramAdmin,
];

export enum UserGroup {
  Institutional = 'institutional-users',
  NonInstitutional = 'non-institutional-users',
  AllRegistered = 'registered-users',
}

export enum Permission {
  ViewPipeline = 'view-pipeline',
  ViewDashboard = 'view-dashboard',
  ViewInstitutionSettings = 'view-institution-settings',
  EditInstitutionSettings = 'edit-institution-settings',
  ViewInstitutionUsers = 'view-institution-users',
  AddInstitutionUsers = 'add-institution-users',
  EditInstitutionUsers = 'edit-institution-users',
  ViewInstitutionAgents = 'view-institution-agents',
  EditInstitutionAgents = 'edit-institution-agents',
  InviteInstitutionAgents = 'invite-institution-agents',
  ViewApplicationSettings = 'view-application-settings',
  EditApplicationSettings = 'edit-application-settings',
  ViewFinancialSettings = 'view-financial-settings',
  EditInvoiceTemplates = 'edit-invoice-templates',
  AddInvoiceTemplates = 'add-invoice-templates',
  CreateInvoice = 'create-invoice',
  CreateLead = 'create-lead',
  ViewLead = 'view-lead',
  EditLead = 'edit-lead',
  LinkApplicationToLead = 'link-application-to-lead',
  ShareApplication = 'share-application',
  SelectLanguage = 'select-language',
  TransferOwnership = 'transfer-ownership',
  UploadDocuments = 'upload-documents',
  AddNotes = 'add-notes',
  ImportApplication = 'import-application',
}
