import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Country from 'components/Country/Country';
import { useParams } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { useGet } from '../hooks/useGet';
import { useNotificationMessages } from '../hooks/useNotificationMessages';
import { getErrorMessage } from '../utils/errors';
import { ResponseEnvelope } from '../types/ResponseEnvelope';
import { LinkWithEnquiryDialog } from './LinkWithEnquiryDialog';

const InlineEnquiry = styled.div`
  display: inline-flex;
`;

const EnquiryName = styled.strong`
  margin: 0 1rem;
  font-size: 1.4rem;
  white-space: nowrap;
`;

export const LinkWithEnquiryButton = ({ hasEnquiry, applicationId }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [loading, getLeadsByApplicant] = useGet<ResponseEnvelope<any[]>>(`/schools/${schoolSlug}/applications/${applicationId}/suggested-leads`);
  const [leads, setLeads] = useState<any[]>([]);
  const [selectedLead, setSelectedLead] = useState<any | undefined>();
  const [showLinkWithLeadDialog, setShowLinkWithLeadDialog] = useState(false);
  const { showErrorMessage } = useNotificationMessages();
  const name = useMemo(() => (selectedLead ? `${selectedLead.first_name} ${selectedLead.last_name}` : null), [selectedLead]);
  const country = useMemo(() => (selectedLead ? selectedLead.country : null), [selectedLead]);

  const fetchLeadsByApplicant = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      const response = await getLeadsByApplicant(params);
      setLeads(response.data);
      if (response.data.length) {
        setSelectedLead(response.data[0]);
      }
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getLeadsByApplicant, showErrorMessage]);

  useEffect(() => {
    if (!hasEnquiry && applicationId) {
      fetchLeadsByApplicant();
    }
  }, [applicationId, hasEnquiry, fetchLeadsByApplicant]);

  if (loading) return <Skeleton variant="text" />;

  return (
    <>
      <Button variant="outlined" onClick={() => setShowLinkWithLeadDialog(true)}>
        Link with enquiry
        {name && country ? (
          <InlineEnquiry>
            :{name ? <EnquiryName>{name}</EnquiryName> : null}
            {country ? <Country hideCode name={country.name} code={country.iso_3166_2.toLowerCase()} /> : null}
          </InlineEnquiry>
        ) : null}
      </Button>
      <LinkWithEnquiryDialog
        leads={leads}
        applicationId={applicationId}
        selectedLead={selectedLead}
        active={showLinkWithLeadDialog}
        onClose={() => setShowLinkWithLeadDialog(false)}
        onSelect={(id) => setSelectedLead(leads.find((l) => l.id === id))}
      />
    </>
  );
};
