import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactCrop, { type Crop } from 'react-image-crop';
import { useDropzone } from 'react-dropzone';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/UploadFile';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Loader from '../../Loader';
import { AppContext } from '../../../app/AppContext';
import { useWorkflow } from '../../../contexts/WorkflowContext';
import { useApplication } from 'contexts/ApplicationContext';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { useDelete } from 'hooks/useDelete';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { WorkflowStepRouteParams } from '../../Workflow/WorkflowApplicationLayout';
import { getErrorMessage } from 'utils/errors';
import { getConfig } from '../../../utils/config';
import { UPLOAD_MAX_SIZE } from 'app/constants/env';
import { grey } from '@mui/material/colors';
import { DisplayWidth } from '../types/api/ApiFormField';
import { fileSize } from 'utils/fileSize';
import { File as FileResource } from 'types/File';

import 'react-image-crop/dist/ReactCrop.css';

interface PictureUploaderFieldProps {
  id: string;
  label: string;
  disabled: boolean;
  value: FileResource;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  aspectRatio?: number;
  displayWidth: DisplayWidth;
}

export const PictureUploaderField = ({ id, label, disabled, value, onChange, error, name, readOnly, aspectRatio, displayWidth }: PictureUploaderFieldProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [crop, setCrop] = useState<Crop>();
  const [showCrop, setShowCrop] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [imageSrc, setImageSrc] = useState<string>('');
  const getPicture = useStatelessGet<Blob>(`/schools/${value?.preview}`, { isBlob: true });
  const [pictureBlob, setPictureBlob] = useState<string>('');

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (value) {
      getPicture().then((res) => setPictureBlob(URL.createObjectURL(res)));
    }
  }, [getPicture, value]);

  const appContext = useContext(AppContext);
  const { stage: stageId, step: stepId, slug } = useParams() as WorkflowStepRouteParams;
  const {
    state: { application },
  } = useApplication();
  const {
    state: { workflow },
  } = useWorkflow();

  const { showErrorMessage } = useNotificationMessages();
  const { apiUrl } = getConfig();
  const theme = useTheme();

  const stage = workflow!.stages.find((stage) => stage.slug === stageId)!;
  const step = stage && stage.steps.find((step) => step.slug === stepId)!;

  const endpoint = application?.id
    ? `schools/${slug}/applications/${application.id}/stages/${stage.id}/steps/${step.id}/fields/${id}/files`
    : `schools/${slug}/files`;

  const [isDeleting, deleteFile] = useDelete(application?.id ? `/${endpoint}/${value?.id}` : `/schools/${slug}/files/${value?.id || ''}`);

  const handleConfirmFileRemove = (onChange) => {
    deleteFile()
      .then(() => {
        onChange(null);
        setShowConfirm(false);
        setPictureBlob('');
      })
      .catch((error) => showErrorMessage(getErrorMessage(error)));
  };

  const handleFileDrop = (acceptedFiles) => {
    setShowCrop(true);
    setFileName(acceptedFiles[0].name);
    setImageSrc(URL.createObjectURL(acceptedFiles[0]));
  };

  const handleUploadFile = (acceptedFile) => {
    setIsUploading(true);
    const fileData = new FormData();

    const file = Object.assign(acceptedFile, {
      preview: URL.createObjectURL(acceptedFile),
    });
    fileData.append('uploadedFile', file);

    const fetchParams = {
      method: 'post',
      headers: appContext.headers,
      body: fileData,
    };

    const fetchEndpoint = `${apiUrl}/${endpoint}`;

    fetch(fetchEndpoint, fetchParams)
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status !== 200) {
          return Promise.reject(json.message || response.statusText);
        }
        return Promise.resolve(json);
      })
      .then((data) => {
        setIsUploading(false);
        onChange(data.data[0]);
      })
      .catch((error) => {
        appContext.operations.onError(error);
        setIsUploading(false);
      });
  };

  const handleConfirmCrop = async () => {
    const imageObj = imgRef.current;
    const canvas = document.createElement('canvas');

    if (!imageObj || !canvas || !crop) {
      throw new Error('Crop canvas does not exist');
    }

    const { x, y, width, height } = crop;
    const context = canvas.getContext('2d');
    imageObj.src = imageSrc;
    const scaleX = imageObj.naturalWidth / imageObj.width;
    const scaleY = imageObj.naturalHeight / imageObj.height;
    canvas.width = width * scaleX;
    canvas.height = height * scaleY;
    if (context) {
      imageObj.onload = () => {
        // Draw the image on the temporary canvas
        context.drawImage(imageObj, x * scaleX, y * scaleY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);

        // Convert the HTMLCanvasElement to Blob
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], fileName, { type: 'image/png' });
            handleUploadFile(file);
          }
        }, 'image/png');
      };
    }

    setShowCrop(false);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    multiple: false,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    disabled: disabled || readOnly,
    maxSize: UPLOAD_MAX_SIZE,
    onDrop: handleFileDrop,
  });

  if (!workflow) return <Loader />;

  const dropzoneIconColor = isDragReject ? theme.palette.error.main : theme.palette.primary.dark;

  const dropzoneBorderColor = isDragReject || error ? theme.palette.error.main : isDragAccept ? theme.palette.primary.main : grey[400];

  const dropzoneBgColor = isDragAccept ? theme.palette.success.light : isDragReject ? theme.palette.error.light : theme.palette.background.default;

  return (
    <FormControl data-cy-field-type="pictureuploader" fullWidth>
      <FormLabel id={`${name}-label`}>{label}</FormLabel>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid xs={12} lg={displayWidth === 'full' ? 6 : 12}>
          <Stack
            minHeight="152px"
            bgcolor={dropzoneBgColor}
            alignItems="center"
            justifyContent="center"
            border={`1px dashed ${dropzoneBorderColor}`}
            borderRadius={theme.spacing(1)}
            sx={{ cursor: 'pointer' }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              {isUploading ? (
                <CircularProgress variant="indeterminate" />
              ) : (
                <>
                  <Box pt={1} pb={0.5}>
                    <UploadIcon sx={{ color: dropzoneIconColor }} />
                  </Box>
                  {!isDragActive && (
                    <Box>
                      <Typography display="inline" color="primary" sx={{ textDecorationLine: 'underline' }}>
                        Click to upload
                      </Typography>
                      <Typography display="inline"> or drag and drop</Typography>
                    </Box>
                  )}
                  {isDragAccept && (
                    <Typography color={theme.palette.primary.dark} fontWeight="bold">
                      Drop file(s) here to upload
                    </Typography>
                  )}
                  {isDragReject && <Typography color="error">File type, size or count is not allowed</Typography>}
                  <Box pt={0.5}>
                    <Typography display="inline" variant="subtitle2" color="secondary">
                      JPG, JPEG or PNG
                    </Typography>
                    <Typography display="inline" variant="subtitle2" color="secondary">
                      (max. 10MB)
                    </Typography>
                  </Box>
                </>
              )}
            </Stack>
          </Stack>
          <Typography color="error">{error ? error!.message : <br />}</Typography>
        </Grid>
        <Grid xs={12} lg={displayWidth === 'full' ? 6 : 12} data-cy="upload-list">
          {value && pictureBlob && (
            <>
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                border={`1px solid ${theme.palette.primary.main}`}
                borderRadius={theme.spacing(1)}
                sx={{ mb: 0.5, p: 2 }}
              >
                <Stack width="100%" direction="row" spacing={2} pb={1}>
                  <Stack m={1} maxWidth="50%">
                    <Box component="img" src={pictureBlob} alt="uploadedPicture" mb={1} sx={{ width: '100%', borderRadius: theme.spacing(1) }} />
                    <Typography>{value.filename}</Typography>
                    <Typography variant="subtitle2" color="secondary" fontSize="small">
                      Uploaded - {fileSize(value.size, 2)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row">
                {!disabled && !readOnly && (
                  <Tooltip title={`Remove file`}>
                    <IconButton aria-label="Remove picture" onClick={() => setShowConfirm(true)} color="error">
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </>
          )}
        </Grid>
      </Grid>
      <Dialog open={showConfirm} onClose={() => setShowConfirm(false)} PaperProps={{ sx: { p: 2 } }}>
        <DialogTitle variant="h4" component="h2">
          Are you sure you want to remove this file?
        </DialogTitle>
        <DialogActions>
          <LoadingButton loading={isDeleting} variant="contained" color="error" onClick={() => handleConfirmFileRemove(onChange)}>
            Remove
          </LoadingButton>
          <Button variant="outlined" color="warning" onClick={() => setShowConfirm(false)} disabled={isDeleting}>
            Keep
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCrop} onClose={() => setShowCrop(false)} PaperProps={{ sx: { p: 2 } }}>
        <DialogTitle variant="h4" component="h2">
          Crop picture
        </DialogTitle>
        <ReactCrop
          crop={crop}
          onChange={(c) => {
            setCrop(c);
          }}
          aspect={aspectRatio}
        >
          <img ref={imgRef} src={imageSrc} alt="Crop" />
        </ReactCrop>
        <DialogActions>
          <LoadingButton disabled={!crop} loading={isDeleting} variant="contained" color="primary" onClick={() => handleConfirmCrop()}>
            Confirm
          </LoadingButton>
          <Button variant="outlined" color="secondary" onClick={() => setShowCrop(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};

// for React.lazy
export default PictureUploaderField;
