import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Loader from 'components/Loader';
import HasAccess from 'permissions/HasAccess';
import { defaultPaymentProviderId as defaultPaymentProviderIdSelector, latestPaymentId as latestPaymentIdSelector } from 'invoices/invoiceSelectors';
import { PAYMENT_STATUS_ADJUSTMENT, PAYMENT_STATUS_COMPLETE } from 'invoices/InvoiceStatuses';
import { UserRole } from 'permissions/constants/Roles';
import { getPaymentProviders } from 'payment/paymentActions';
import { voidPayment } from 'invoices/invoiceActions';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, FormControl, FormLabel, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Controller, useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils/errors';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { usePost } from 'hooks/usePost';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import currency, { decimal } from 'currency/currency';
import { format, isValid } from 'date-fns';
import { DATE_DASH_FORMAT } from 'app/constants/DateFormats';
import { getInvoiceBalanceDue } from '../../invoices/invoiceUtils';
import { useLocale } from 'contexts/LocaleContext';
import { useSchool } from 'contexts/SchoolContext';

interface AddPaymentFormValues {
  paymentDate: Date;
  amountReceived: string;
}

interface PostInvoicePaymentPayload {
  payment_provider_id: string;
  amount: number;
  status: string;
  payment_date?: string;
}

export const AddPayment = ({ onPayment, invoice }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [paymentDateFormatted, setPaymentDateFormatted] = useState(format(new Date(), DATE_DASH_FORMAT));
  const dispatch = useAppDispatch();
  const {
    state: { school },
  } = useSchool();
  const currencyCode = school?.currency_code || 'NZD';
  const { locale } = useLocale();
  const balanceDue = getInvoiceBalanceDue(invoice, currencyCode, locale);
  const defaultPaymentProviderId = useAppSelector(defaultPaymentProviderIdSelector);
  const latestPaymentId = useAppSelector(latestPaymentIdSelector);
  const fetchStatus = useAppSelector((state) => state.payment.status);
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<AddPaymentFormValues>({
    defaultValues: { paymentDate: new Date(), amountReceived: `${balanceDue}` },
  });
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();
  const [loading, postInvoicePayment] = usePost<ResponseEnvelope<any>, PostInvoicePaymentPayload>(`/schools/${schoolSlug}/invoices/${invoice.id}/payments`);
  const amountReceivedWatch = watch('amountReceived');
  const amountReceivedFormatted = currency(amountReceivedWatch, currencyCode, locale);

  useEffect(() => {
    if (invoice.application) {
      dispatch(getPaymentProviders(invoice.application, schoolSlug));
    }
  }, [dispatch, invoice.application, schoolSlug]);

  const onSubmit = handleSubmit(async (data: AddPaymentFormValues) => {
    // Refunds are 'adjustments'
    const status = parseFloat(data.amountReceived) > 0 ? PAYMENT_STATUS_COMPLETE : PAYMENT_STATUS_ADJUSTMENT;

    const payload: PostInvoicePaymentPayload = {
      payment_provider_id: defaultPaymentProviderId,
      amount: decimal(data.amountReceived, currencyCode, locale),
      status,
      ...(paymentDateFormatted && { payment_date: paymentDateFormatted }),
    };

    try {
      // void any non-bank payment that is in progress if the invoice has been paid via bank payment instead
      if (latestPaymentId) {
        dispatch(voidPayment(latestPaymentId, schoolSlug));
      }

      await postInvoicePayment(payload);
      onPayment();
      showSuccessMessage('Payment applied');
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  });

  const isDisabled = loading || !balanceDue;

  if (fetchStatus === 'loading') {
    return <Loader center />;
  }

  return (
    <HasAccess for="role" name={[UserRole.InstitutionalStaff, UserRole.InstitutionalAdmin]}>
      <form onSubmit={onSubmit}>
        <Typography variant="h6" component="h3" sx={{ mt: 4, mb: 2 }}>
          Add a manual payment to this invoice
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'inherit', md: 'flex-end' }} spacing={2}>
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
            <Stack>
              <FormControl required data-cy="add-payment-date">
                <FormLabel id="paymentDateLabel">Payment date</FormLabel>
                <Controller
                  name="paymentDate"
                  control={control}
                  rules={{ required: 'Payment date is required' }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      value={value}
                      disabled={isDisabled}
                      onChange={(newValue) => {
                        if (isValid(newValue)) {
                          setPaymentDateFormatted(format(newValue!, DATE_DASH_FORMAT));
                          onChange(newValue);
                        }
                      }}
                      slotProps={{ textField: { size: 'small', required: true, 'aria-labelledby': 'paymentDateLabel' } }}
                    />
                  )}
                />
              </FormControl>
              {errors.paymentDate && <Typography color="error">{errors.paymentDate.message}</Typography>}
            </Stack>
            <Stack>
              <FormControl required>
                <FormLabel id="amountReceivedLabel">Amount received</FormLabel>
                <Controller
                  name="amountReceived"
                  control={control}
                  rules={{
                    required: 'Amount is required',
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      aria-labelledby="amountReceivedLabel"
                      type="number"
                      name="amountReceived"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      disabled={loading || isDisabled}
                      onChange={(newValue) => onChange(newValue)}
                      value={value}
                      required
                    />
                  )}
                />
              </FormControl>
              {errors.amountReceived && <Typography color="error">{errors.amountReceived.message}</Typography>}
            </Stack>
          </Stack>
          <Box flex="1" />
          <LoadingButton variant="outlined" disabled={isDisabled} loading={loading} data-cy="add-payment" onClick={onSubmit}>
            <Typography display="inline" color="inherit">
              Add {parseFloat(amountReceivedWatch || '0') > 0 ? ' payment ' : ' adjustment '} of
            </Typography>
            <Typography display="inline" color="inherit" fontWeight="bold" ml={1}>
              {amountReceivedFormatted}
            </Typography>
          </LoadingButton>
        </Stack>
      </form>
    </HasAccess>
  );
};
