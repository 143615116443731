import { ReactNode, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormField } from 'components/FormBuilder/FormField';
import { ApiFormBuilderStep } from 'components/Workflow/WorkflowFormBuilder';
import { usePost } from 'hooks/usePost';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import spacetime from 'spacetime';
import { useApplication } from 'contexts/ApplicationContext';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { FormBuilderField } from './types/FormBuilderField';
import { usePut } from 'hooks/usePut';
import { parseISO } from 'date-fns';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useSubmitContext } from 'components/Workflow/SubmitContext';
import { FormBuilderFieldType } from './types/FormBuilderFieldType.enum';
import { displayWidthToGrid } from './utils/displayWidthToGrid';

export const getValuesFromFormData = (formData: FormBuilderField[], questions = {}, answers = {}) => {
  const isEmpty = (v) => v === undefined || v === null || v === '' || (Array.isArray(v) && v.length === 0);

  return formData.reduce((formState, field) => {
    switch (field.type) {
      case FormBuilderFieldType.DatePicker:
        formState[field.slug] = isEmpty(field.value) ? null : parseISO(field.value!);
        break;

      case FormBuilderFieldType.LocalTime: {
        if (isEmpty(field.value)) {
          const localTimezone = spacetime.now().timezone().name;
          formState[field.slug] = [null, '', localTimezone];
        } else {
          formState[field.slug] = field.value;
          // if the date is set, parse it
          if (field.value![0] && typeof field.value![0] === 'string') {
            formState[field.slug][0] = parseISO(field.value![0]);
          }
          // Convert time string to date object
          if (field.value![1] && typeof field.value![1] === 'string') {
            const [hours, minutes] = field.value![1].split(':');
            const timeDateObject = new Date();
            timeDateObject.setHours(parseInt(hours));
            timeDateObject.setMinutes(parseInt(minutes));
            formState[field.slug][1] = timeDateObject;
          }
        }
        break;
      }

      case FormBuilderFieldType.CountryAndTimezone: {
        if (isEmpty(field.value)) {
          formState[field.slug] = [null, ''];
        } else {
          formState[field.slug] = field.value;
          if (typeof field.value![0] === 'string') {
            formState[field.slug][0] = parseInt(field.value![0], 10);
          }
        }
        break;
      }

      case FormBuilderFieldType.MonthYear:
        formState[field.slug] = isEmpty(field.value) ? ['', ''] : field.value;
        break;

      case FormBuilderFieldType.StartAndEndDate:
        if (isEmpty(field.value)) {
          formState[field.slug] = [null, null];
        } else {
          formState[field.slug] = field.value;
          // if the date is set, parse it
          // TODO: fix wrong value format coming from API which puts default value in an array
          let fieldValue = field.value;
          if (fieldValue!.length === 1) fieldValue = fieldValue![0];
          if (!isEmpty(fieldValue![0]) && typeof fieldValue![0] === 'string') {
            formState[field.slug][0] = parseISO(fieldValue![0]);
          }
          if (!isEmpty(fieldValue![1]) && typeof fieldValue![1] === 'string') {
            formState[field.slug][1] = parseISO(fieldValue![1]);
          }
        }
        break;

      case FormBuilderFieldType.Country:
        if (typeof field.value === 'string' && !isEmpty(field.value)) {
          formState[field.slug] = parseInt(field.value, 10);
        } else {
          formState[field.slug] = null;
        }
        break;

      case FormBuilderFieldType.AutoComplete:
        formState[field.slug] = isEmpty(field.value) ? [] : field.value;
        break;

      case FormBuilderFieldType.ConditionsCheckboxSet:
        formState[field.slug] = isEmpty(field.value) ? [] : field.value;
        break;

      case FormBuilderFieldType.ApiJson:
        formState[field.slug] = typeof field.value === 'string' ? field.value : JSON.stringify(field.value, null, 4);
        break;

      case FormBuilderFieldType.CreateInvoice:
        formState[field.slug] = {
          invoice_number: '',
          due_date: null,
          items: [],
          payments: [],
          notes: '',
          xeroUrl: '',
        };
        break;

      case FormBuilderFieldType.AccountSummary:
        formState[field.slug] = {
          items: [],
        };
        break;

      case FormBuilderFieldType.Checkbox:
        formState[field.slug] = !!field.value;
        break;

      case FormBuilderFieldType.CustomFieldsPlaceholder:
        const questionKeys = Object.keys(questions);
        if (questions) {
          questionKeys.forEach((questionId) => {
            formState[questionId] = '';
            Object.keys(answers).forEach((answerName) => {
              if (answerName === questions[questionId] && answers[answerName] !== null) {
                formState[questionId] = answers[answerName];
              }
            });
          });
        }
        formState[field.slug] = '';
        break;

      case FormBuilderFieldType.Time:
        // Convert time string to date object
        if (field.value && typeof field.value === 'string') {
          const [hours, minutes] = field.value.split(':');
          const timeDateObject = new Date();
          timeDateObject.setHours(parseInt(hours));
          timeDateObject.setMinutes(parseInt(minutes));
          formState[field.slug] = timeDateObject;
        }
        break;

      case FormBuilderFieldType.ReviewStep:
        if (!field.value) {
          // Initial value when review hasn't been started by the reviewer
          formState[field.slug] = {
            status: 'unreviewed',
            fields: new Map(),
          };
        } else {
          // Create a map based on the array of fields [slug, comment]
          const fieldsMap = new Map(Object.entries(field.value.fields));
          formState[field.slug] = { status: field.value.status, fields: fieldsMap };
        }
        break;

      default:
        formState[field.slug] = field.value;
        break;
    }

    return formState;
  }, {});
};

interface FormBuilderProps {
  fieldsMap: Map<string, FormBuilderField>;
  submitUrl: string;
  preSubmit?: (data: unknown) => unknown;
  postSubmit?: (data: any) => void;
  formActions: (loading: boolean, submit: (data: any) => void, getValues: () => any) => ReactNode;
  renderFieldStepActions?: (loading: boolean, submit: (data: any) => void, getValues: () => any, name: string) => ReactNode;
  formHeading?: () => ReactNode;
  method?: 'POST' | 'PUT';
  readOnly?: boolean;
}

export const FormBuilder = ({
  fieldsMap,
  submitUrl,
  preSubmit,
  postSubmit,
  formActions,
  formHeading,
  renderFieldStepActions,
  method = 'POST',
  readOnly = false,
}: FormBuilderProps) => {
  const {
    state: { application },
  } = useApplication();
  const {
    control,
    handleSubmit: handleSubmitRHF,
    getValues,
    trigger,
  } = useForm({
    defaultValues: getValuesFromFormData(Array.from(fieldsMap.values()), application?.custom_questions || {}, application?.custom_question_answers || {}),
  });

  const { setSubmit, setIsFormLoading } = useSubmitContext();

  const [postStepLoading, postStep] = usePost<ResponseEnvelope<ApiFormBuilderStep>>(submitUrl);
  const [putStepLoading, putStep] = usePut<ResponseEnvelope<ApiFormBuilderStep>>(submitUrl);

  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const handleSubmit = useCallback(
    async (data: Record<string, string | Date | null | any>) => {
      try {
        const body = preSubmit ? preSubmit(data) : data;
        const response = method === 'POST' ? await postStep(body) : await putStep(body);
        const { success, message } = response;

        if (success) {
          showSuccessMessage(message);
          postSubmit?.(response);
        } else {
          showErrorMessage(message);
        }
      } catch (error) {
        showErrorMessage(getErrorMessage(error));
      }
    },
    [method, postStep, postSubmit, preSubmit, putStep, showErrorMessage, showSuccessMessage],
  );

  const onSubmit = handleSubmitRHF(handleSubmit, () => {
    // scroll to top invalid element
    // setTimeout gives time for render loop to update invalid statuses
    setTimeout(() => {
      const invalidElements = document.querySelectorAll('[aria-invalid="true"]');
      invalidElements?.[0]?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
  });
  const isFormLoading = postStepLoading || putStepLoading;

  // Used to ensure the function passed to SubmitContext through setSubmit has access to the response data
  // returned by handleSubmit
  useEffect(() => {
    setSubmit(() => handleSubmit(getValues()));
    setIsFormLoading(isFormLoading);
  }, [handleSubmit, getValues, setSubmit, setIsFormLoading, isFormLoading]);

  return (
    <form onSubmit={onSubmit}>
      <Grid
        container
        justifyContent="center"
        spacing={3}
        sx={{
          backgroundColor: 'white',
          boxShadow: '0px 1px 2px 0px #1018280D',
          p: 2,
          borderRadius: (theme) => theme.spacing(1),
          m: 0,
        }}
      >
        {formHeading && <Grid xs={12}>{formHeading()}</Grid>}
        {Array.from(fieldsMap.values()).map((field) => (
          <FormField
            key={field.slug}
            {...{
              field,
              control,
              readOnly,
              trigger,
              fields: Array.from(fieldsMap.values()),
              fieldWrapper: (children) => (
                <Grid xs={12} lg={displayWidthToGrid[field.displayWidth]}>
                  {children}
                </Grid>
              ),
              renderFieldStepActions: (name) => renderFieldStepActions?.(postStepLoading || putStepLoading, handleSubmit, getValues, name),
            }}
          />
        ))}
      </Grid>
      {formActions(isFormLoading, handleSubmit, getValues)}
    </form>
  );
};
