import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import { usePost } from 'hooks/usePost';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { ButtonProps } from '@mui/material';
import { Application } from 'types/Application';

interface EntrypointProps {
  actionId: string;
  label: string;
  disabled?: boolean;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const Entrypoint = ({ actionId, label, disabled, color, variant }: EntrypointProps) => {
  const { slug } = useParams() as ApplicantRouteParams;
  const navigate = useNavigate();
  const [isSubmitting, postEntrypoint] = usePost<ResponseEnvelope<Application>>(`/schools/${slug}/entrypoints/${actionId}`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const handleSubmit = () => {
    postEntrypoint()
      .then((response) => {
        const { success, data, message } = response;

        if (success) {
          showSuccessMessage(message);
          const { applicant_id: applicantId, id: applicationId } = data;
          navigate(`/${slug}/enrolment-application/applicant/${applicantId}/application/${applicationId}`);
        } else {
          showErrorMessage(message);
        }
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  return (
    <LoadingButton variant={variant} color={color} loading={isSubmitting} disabled={disabled} onClick={handleSubmit}>
      {label}
    </LoadingButton>
  );
};
