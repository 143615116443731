import { createSelector } from 'reselect';
import { decimal } from 'currency/currency';
import { BANK_PAYMENT_PROVIDER } from 'payment/PaymentProviders';
import {
  INVOICE_STATUS_VOIDED,
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_CREATED,
  PAYMENT_STATUS_OVERPAID,
  PAYMENT_STATUS_VOIDED,
} from 'invoices/InvoiceStatuses';

const paymentsSelector = (state) => state.invoice.payments;
const paymentProvidersSelector = (state) => state.payment.paymentProviders;

export const invoiceBalanceDue = (state, locale, currencyCode) => {
  if (state.invoice.invoiceStatus === INVOICE_STATUS_VOIDED) {
    return decimal(0, currencyCode, locale);
  }

  const excludedStatuses = [PAYMENT_STATUS_CREATED, PAYMENT_STATUS_VOIDED, PAYMENT_STATUS_CANCELLED, PAYMENT_STATUS_OVERPAID];
  const total = parseFloat(state.invoice.total || 0);
  const completedPayments = state.invoice.payments.filter((payment) => !excludedStatuses.includes(payment.status));
  const paymentsTotal = completedPayments.reduce((runningTotal, payment) => runningTotal + parseFloat(payment.amount), 0);
  const balanceDue = total - paymentsTotal;
  // TODO: Get from localeContext
  return decimal(balanceDue, currencyCode, locale);
};

export const invoiceBalanceDueInCents = createSelector(invoiceBalanceDue, (total) => {
  return (total * 100).toString();
});

export const hasBalanceDue = createSelector(invoiceBalanceDue, paymentsSelector, (balanceDue, payments) => {
  if (!payments.length) {
    return true;
  }

  // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  return parseFloat(balanceDue) > 0;
});

export const defaultPaymentProviderId = createSelector(paymentProvidersSelector, (paymentProviders) => {
  if (paymentProviders && paymentProviders.length) {
    const bank = paymentProviders.find((provider) => provider.slug === BANK_PAYMENT_PROVIDER);

    return bank ? bank.id : null;
  }

  return null;
});

export const latestPaymentId = createSelector(paymentsSelector, (payments) => {
  if (!payments.length) {
    return null;
  }

  const nonDefaultProviderPayment = payments.filter((payment) => {
    return payment.payment_provider.slug !== BANK_PAYMENT_PROVIDER && payment.status === PAYMENT_STATUS_CREATED;
  });

  if (nonDefaultProviderPayment.length) {
    return nonDefaultProviderPayment[0].id;
  }

  return null;
});
