import * as React from 'react';
import { useSchool } from '../contexts/SchoolContext';

export const HasFeature = ({ children, fallback = <></>, name }) => {
  const { hasFeatures } = useSchool();

  if (hasFeatures(name)) return <>{children}</>;

  return <>{fallback}</>;
};

export default HasFeature;
