import { AsyncStatus } from '../types/AsyncStatus';
import { Workflow } from '../components/Workflow/types/Workflow';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer } from 'react';
import { useSchool } from './SchoolContext';

type WorkflowAction =
  | { type: 'start workflow update'; updates: Partial<Workflow> }
  | { type: 'finish workflow update'; workflow: Workflow }
  | { type: 'fail workflow update'; error: unknown }
  | { type: 'start workflow read' }
  | { type: 'finish workflow read'; workflow: Workflow }
  | { type: 'fail workflow read'; error: unknown };
type WorkflowState = {
  status: AsyncStatus;
  workflow: Workflow | null;
};
interface WorkflowContextData {
  state: WorkflowState;
}

const WorkflowContext = createContext<WorkflowContextData | undefined>(undefined);

const workflowReducer = (state: WorkflowState, action: WorkflowAction): WorkflowState => {
  switch (action.type) {
    case 'start workflow read':
    case 'start workflow update':
      return { ...state, status: 'pending' };
    case 'finish workflow read':
    case 'finish workflow update':
      return {
        status: 'success',
        workflow: action.workflow,
      };
    case 'fail workflow read':
    case 'fail workflow update':
      console.error(action.error);
      return {
        status: 'error',
        workflow: null,
      };
  }
};

export const WorkflowProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(workflowReducer, {
    status: 'idle',
    workflow: null,
  });
  const {
    state: { school },
  } = useSchool();
  const workflow = school?.workflow;

  const loadWorkflow = useCallback(async () => {
    if (workflow) {
      dispatch({ type: 'start workflow read' });

      try {
        // TODO: replace current redux selector getting workflow details
        dispatch({
          type: 'finish workflow read',
          workflow,
        });
      } catch (error) {
        dispatch({ type: 'fail workflow read', error });
      }
    }
  }, [workflow]);

  useEffect(() => {
    loadWorkflow();
  }, [loadWorkflow]);

  return <WorkflowContext.Provider value={{ state }}>{children}</WorkflowContext.Provider>;
};

export const useWorkflow = () => {
  const context = useContext(WorkflowContext);

  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }

  return context;
};
