import { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { PostSubmissionMessage } from 'workflow/components/PostSubmissionMessage';
import { StepForm } from 'workflow/components/StepForm';
import { useWorkflow } from 'contexts/WorkflowContext';
import { useParams } from 'react-router-dom';
import { usePageTitle } from 'contexts/PageTitleContext';
import { useApplicant } from 'contexts/ApplicantContext';
import { useApplication } from 'contexts/ApplicationContext';
import { usePermissions } from 'contexts/PermissionContext';
import Loader from 'components/Loader';

import { WorkflowStepRouteParams } from './WorkflowApplicationLayout';
import { useSchool } from 'contexts/SchoolContext';

export const WorkflowStep = () => {
  const { stage: stageParam, step: stepParam, slug, applicant: applicantId, application: applicationId } = useParams() as WorkflowStepRouteParams;
  const [postSubmissionMessage, setPostSubmissionMessage] = useState(null);
  const { hasGroup } = usePermissions();
  const { setPageTitle } = usePageTitle();
  const {
    state: { school },
  } = useSchool();
  const schoolName = school?.name;
  const {
    state: { applicant },
  } = useApplicant();
  const {
    state: { application },
  } = useApplication();
  const {
    state: { workflow },
  } = useWorkflow();

  const displayName = applicant?.first_name && applicant?.last_name ? `${applicant?.first_name} ${applicant?.last_name}` : 'New Applicant';
  const stage = workflow?.stages.find((stage) => stage.slug === stageParam);
  const step = stage?.steps.find((step) => step.slug === stepParam);

  useEffect(
    () => setPageTitle(`${schoolName} | ${displayName} | ${stage?.name || 'Stage'} &raquo; ${step?.name || 'Step'}`),
    [displayName, schoolName, setPageTitle, stage?.name, step?.name],
  );

  if (!application || !workflow || !stage || !step) return <Loader />;

  const previousStep = stage.steps.find((stageStep) => stageStep.order === step.order - 1);
  const applicationUrl = `/${slug}/enrolment-application/applicant/${applicantId}/application/${applicationId}`;
  const stepActionUrl = `/schools/${slug}/applications/${applicationId}/stages/${stage.id}/steps/${step.id}`;
  const previousStepUrl = previousStep && hasGroup([previousStep.assign_to]) ? `${applicationUrl}/stages/${stage.slug}/steps/${previousStep.slug}` : null;
  const workflowActionUrl = `/schools/${slug}/applications/${applicationId}`;

  return (
    <Paper
      sx={{
        p: 2,
        mb: 2,
        borderRadius: (theme) => theme.spacing(1),
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: (theme) => theme.palette.grey[50],
        boxShadow: (theme) => `0px 1px 2px 0px ${theme.palette.shadows.light}`,
      }}
    >
      {!postSubmissionMessage ? (
        <StepForm
          heading={step.name}
          translationKey={step.name_translation_key}
          stepActionUrl={stepActionUrl}
          workflowActionUrl={workflowActionUrl}
          previousStepUrl={previousStepUrl}
          showPostSubmissionMessage={setPostSubmissionMessage}
        />
      ) : (
        <PostSubmissionMessage message={postSubmissionMessage} translationKey={step.post_submission_message_translation_key} backLink={applicationUrl} />
      )}
    </Paper>
  );
};
