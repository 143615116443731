import { useEffect } from 'react';
import { getConfig } from 'utils/config';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from 'utils/user';
import { useAppSelector } from 'app/hooks';
import { getIntercomData } from '../../utils/intercom';
import { useSchool } from 'contexts/SchoolContext';

export const useIntercom = () => {
  const location = useLocation();
  const { user } = useAuth0<AuthUser>();
  const {
    state: { school },
  } = useSchool();
  const schoolName = school?.name ?? '';
  const applicationId = useAppSelector((state: any) => state.application.id);
  const { environment, intercomAppId } = getConfig();

  useEffect(() => {
    if (environment === 'local' || !user || !intercomAppId) {
      return;
    }

    window.Intercom('update', getIntercomData(user, schoolName, intercomAppId, applicationId));
  }, [applicationId, environment, intercomAppId, location, schoolName, user]);
};
