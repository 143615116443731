import { useSchool } from 'contexts/SchoolContext';

export const ProductsAccess = (props) => {
  const {
    state: { school },
  } = useSchool();
  const productLevel = school?.product_level;
  let hasAccess = true;

  if (props.disallow && props.disallow.length > 0) {
    if (props.disallow.includes(productLevel)) {
      hasAccess = false;
    }
  }

  if (props.allow && props.allow.length > 0) {
    if (!props.allow.includes(productLevel)) {
      hasAccess = false;
    }
  }

  if (hasAccess) {
    return props.children;
  } else if (props.fallback) {
    return props.fallback;
  }

  return <></>;
};

// for React.lazy
export default ProductsAccess;
