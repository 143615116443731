import { useContext, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { AppContext } from '../../../app/AppContext';
import { useWorkflow } from '../../../contexts/WorkflowContext';
import Upload from '../../../components/FileUpload/Upload';
import UploadIcon from '@mui/icons-material/UploadFile';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, FormLabel, Stack, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useApplication } from 'contexts/ApplicationContext';
import { WorkflowStepRouteParams } from '../../Workflow/WorkflowApplicationLayout';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';

import { ControllerRenderProps, FieldError } from 'react-hook-form';
import Loader from '../../Loader';
import { getConfig } from '../../../utils/config';
import { UPLOAD_MAX_SIZE } from 'app/constants/env';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DisplayWidth } from '../types/api/ApiFormField';
import { useDelete } from 'hooks/useDelete';
import { LoadingButton } from '@mui/lab';
import { File as FileResource } from 'types/File';

interface FileFieldProps {
  id: string;
  label: string;
  disabled: boolean;
  value: FileResource[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  allowMultiple?: boolean;
  acceptedMimeTypes?: Accept;
  displayWidth: DisplayWidth;
}

export const FileField = ({
  id,
  label,
  disabled,
  value,
  onChange,
  error,
  name,
  readOnly,
  allowMultiple = false,
  acceptedMimeTypes,
  displayWidth,
}: FileFieldProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [fileToDeleteId, setFileToDeleteId] = useState<FileResource['id'] | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const appContext = useContext(AppContext);
  const { stage: stageId, step: stepId, slug } = useParams() as WorkflowStepRouteParams;
  const {
    state: { application },
  } = useApplication();
  const {
    state: { workflow },
  } = useWorkflow();

  const { showErrorMessage } = useNotificationMessages();
  const { apiUrl } = getConfig();
  const theme = useTheme();

  const stage = workflow!.stages.find((stage) => stage.slug === stageId)!;
  const step = stage && stage.steps.find((step) => step.slug === stepId)!;

  const endpoint = application?.id
    ? `schools/${slug}/applications/${application.id}/stages/${stage.id}/steps/${step.id}/fields/${id}/files`
    : `schools/${slug}/files`;

  const [isDeleting, deleteFile] = useDelete(application?.id ? `/${endpoint}/${fileToDeleteId}` : `/schools/${slug}/files/${fileToDeleteId || ''}`);

  const handleFileRemove = (file: FileResource) => {
    setShowConfirm(true);
    setFileToDeleteId(file.id);
  };

  const handleClose = () => {
    setShowConfirm(false);
    setFileToDeleteId(null);
  };

  const handleConfirmFileRemove = (currentFileList: FileResource[], onChange) => {
    const newfileList = currentFileList.filter((file) => file.id !== fileToDeleteId);
    setFileToDeleteId(null);
    deleteFile()
      .then(() => {
        onChange(newfileList);
        setShowConfirm(false);
      })
      .catch((error) => showErrorMessage(getErrorMessage(error)));
  };

  const handleFileDrop = (acceptedFiles) => {
    if (!acceptedFiles.length) return;

    setIsUploading(true);
    const fileData = new FormData();
    const existingFiles = value?.slice() || [];

    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = Object.assign(acceptedFiles[i], {
        preview: URL.createObjectURL(acceptedFiles[i]),
      });
      fileData.append(`uploadedFile-${i}`, file);
    }

    const fetchParams = {
      method: 'post',
      headers: appContext.headers,
      body: fileData,
    };

    const fetchEndpoint = `${apiUrl}/${endpoint}`;

    fetch(fetchEndpoint, fetchParams)
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status !== 200) {
          return Promise.reject(json.message || response.statusText);
        }
        return Promise.resolve(json);
      })
      .then((data) => {
        data.data.forEach((file) => existingFiles.push(file));
        setIsUploading(false);
        onChange(existingFiles);
      })
      .catch((error) => {
        appContext.operations.onError(error);
        setIsUploading(false);
      });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    multiple: allowMultiple,
    accept: acceptedMimeTypes,
    disabled: disabled || readOnly,
    maxSize: UPLOAD_MAX_SIZE,
    onDrop: handleFileDrop,
  });

  if (!workflow) return <Loader />;

  const dropzoneIconColor = isDragReject ? theme.palette.error.main : theme.palette.primary.dark;

  const dropzoneBorderColor = isDragReject || error ? theme.palette.error.main : isDragAccept ? theme.palette.primary.main : grey[400];

  const dropzoneBgColor = isDragAccept ? theme.palette.success.light : isDragReject ? theme.palette.error.light : theme.palette.background.default;

  const acceptedExts = Object.values(acceptedMimeTypes || { '': [''] })
    .flat()
    .map((ext) => ext.replace('.', '').toUpperCase())
    .sort();
  const lastExt = acceptedExts[acceptedExts.length - 1];
  const acceptedExtsLabel = acceptedExts.length > 1 ? acceptedExts.slice(0, -1).join(', ').concat(' or ', lastExt) : acceptedExts[0];

  return (
    <FormControl data-cy-field-type="file" fullWidth>
      <FormLabel id={`${name}-label`}>{label}</FormLabel>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid xs={12} lg={displayWidth === 'full' ? 6 : 12}>
          <Stack
            minHeight="152px"
            bgcolor={dropzoneBgColor}
            alignItems="center"
            justifyContent="center"
            border={`1px dashed ${dropzoneBorderColor}`}
            borderRadius={theme.spacing(1)}
            sx={{ cursor: 'pointer' }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              {isUploading ? (
                <CircularProgress variant="indeterminate" />
              ) : (
                <>
                  <Box pt={1} pb={0.5}>
                    <UploadIcon sx={{ color: dropzoneIconColor }} />
                  </Box>
                  {!isDragActive && (
                    <Box>
                      <Typography display="inline" color="primary" sx={{ textDecorationLine: 'underline' }}>
                        Click to upload
                      </Typography>
                      <Typography display="inline"> or drag and drop</Typography>
                    </Box>
                  )}
                  {isDragAccept && (
                    <Typography color={theme.palette.primary.dark} fontWeight="bold">
                      Drop file(s) here to upload
                    </Typography>
                  )}
                  {isDragReject && <Typography color="error">File type, size or count is not allowed</Typography>}
                  <Box pt={0.5}>
                    {acceptedExtsLabel && (
                      <Typography display="inline" variant="subtitle2" color="secondary">
                        {acceptedExtsLabel}{' '}
                      </Typography>
                    )}
                    <Typography display="inline" variant="subtitle2" color="secondary">
                      (max. 10MB)
                    </Typography>
                  </Box>
                </>
              )}
            </Stack>
          </Stack>
          <Typography color="error">{error ? error!.message : <br />}</Typography>
        </Grid>
        <Grid xs={12} lg={displayWidth === 'full' ? 6 : 12} data-cy="upload-list">
          {(value || []).map((file, index) => (
            <Upload
              // TODO: Not use index in key. It can't be trusted.
              key={`file-${index}`}
              index={index}
              name={file.filename}
              preview={file.preview}
              size={file.size}
              type={file.mime}
              canRemove={!disabled}
              onRemove={() => handleFileRemove(file)}
            />
          ))}
        </Grid>
      </Grid>
      <Dialog open={showConfirm} onClose={handleClose} PaperProps={{ sx: { p: 2 } }}>
        <DialogTitle variant="h4" component="h2">
          Are you sure you want to remove this file?
        </DialogTitle>
        <DialogActions>
          <LoadingButton loading={isDeleting} variant="contained" color="error" onClick={() => handleConfirmFileRemove(value, onChange)}>
            Remove
          </LoadingButton>
          <Button variant="outlined" color="warning" onClick={handleClose} disabled={isDeleting}>
            Keep
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};

// for React.lazy
export default FileField;
