import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Avatar, Skeleton } from '@mui/material';
import { useGet } from 'hooks/useGet';
import { useEffect, useState } from 'react';
import { File } from 'types/File';

interface ApplicantSummaryPhotoProps {
  file: File;
  size: `${number}px` | number;
}

export const ApplicantSummaryPhoto = ({ file, size }: ApplicantSummaryPhotoProps) => {
  const [blobUrl, setBlobUrl] = useState<string>('');
  const [isLoading, getPhoto] = useGet<Blob>(`/schools/${file.preview}`, { isBlob: true });
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getPhoto()
      .then((blob) => setBlobUrl(URL.createObjectURL(blob)))
      .catch((error) => {
        console.error('Failed to load applicant photo from API', error);
        setIsError(true);
      });
  }, [getPhoto]);

  return (
    <>
      {isLoading && <Skeleton variant="rounded" width={size} height={size} animation="wave" />}
      {blobUrl && <Avatar src={blobUrl} alt="Applicant photo" variant="rounded" sx={{ width: size, height: size }} />}
      {isError && (
        <Avatar variant="rounded" sx={{ width: size, height: size }}>
          <PersonOutlinedIcon />
        </Avatar>
      )}
    </>
  );
};
