import fileDownload from 'js-file-download';
import { fileSize } from 'utils/fileSize';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, CircularProgress, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import { GradientIcon } from 'components/GradientIcon/GradientIcon';

import FilePreviewDialog from './FilePreviewDialog';
import { useGet } from 'hooks/useGet';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';

interface UploadProps {
  canRemove: boolean;
  directLink?: boolean;
  index: number;
  name: string;
  onRemove: (index: number) => any;
  preview: string;
  size: number;
  type: string;
}

export const Upload = ({ canRemove, directLink = false, index, name, onRemove, preview, size, type }: UploadProps) => {
  const theme = useTheme();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileBlob, setFileBlob] = useState<Blob | undefined>();
  const fileUrl = `/schools/${preview}`;
  const fetchOptions = { isBlob: true };
  // Attach loading state to correct button
  const [isPreviewLoading, getPreview] = useGet<Blob>(fileUrl, fetchOptions);
  const [isDownloading, getDownload] = useGet<Blob>(fileUrl, fetchOptions);
  const { showErrorMessage } = useNotificationMessages();

  const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp', 'image/webp'].includes(type);
  const isPdf = type === 'application/pdf';

  const handleRemove = (index, e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove(index);
  };

  const handleImagePreview = async () => {
    try {
      const blob = await getPreview();
      setFileBlob(blob);
      setPreviewOpen(true);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

  const handlePdfPreview = async () => {
    try {
      const blob = await getPreview();
      window.open(URL.createObjectURL(blob));
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

  const handleDownload = async () => {
    try {
      const blob = await getDownload();
      if (!blob) throw new Error('File has no content');
      fileDownload(blob, name);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius={theme.spacing(1)}
        sx={{ mb: 0.5, p: 2 }}
      >
        <Stack width="100%" direction="row" spacing={2} pb={1}>
          <GradientIcon color="primary" IconComponent={isImage ? PhotoOutlinedIcon : DescriptionOutlinedIcon} />
          <Stack width="100%" m={1}>
            <Typography>{name}</Typography>
            <Typography variant="subtitle2" color="secondary" fontSize="small">
              Uploaded - {fileSize(size, 2)}
            </Typography>
          </Stack>
          <Box flex="1" />
          <CheckCircleIcon color="success" />
        </Stack>
      </Stack>
      <Stack direction="row">
        {isImage && (
          <Tooltip title="Preview image">
            <IconButton onClick={handleImagePreview}>{isPreviewLoading ? <CircularProgress size="24px" /> : <VisibilityOutlinedIcon />}</IconButton>
          </Tooltip>
        )}
        {isPdf && (
          <Tooltip title="Preview in new tab">
            <IconButton onClick={handlePdfPreview}>{isPreviewLoading ? <CircularProgress size="24px" /> : <OpenInNewIcon />}</IconButton>
          </Tooltip>
        )}
        <Tooltip title={`Download file`}>
          <IconButton
            href={directLink ? preview : '#'}
            target={directLink ? '_blank' : '_self'}
            rel="noopener noreferrer"
            onClick={() => {
              if (directLink) return false;
              handleDownload();
            }}
          >
            {isDownloading ? <CircularProgress size="24px" /> : <DownloadIcon />}
          </IconButton>
        </Tooltip>
        {canRemove && (
          <Tooltip title={`Remove file`}>
            <IconButton aria-label="Remove file" onClick={(event) => handleRemove(index, event)} color="error">
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {fileBlob && (
        <FilePreviewDialog type={isImage ? 'image' : 'pdf'} fileBlob={fileBlob} fileName={name} open={previewOpen} onToggle={() => setPreviewOpen(false)} />
      )}
    </>
  );
};

export default Upload;
