import { useState } from 'react';
import styled from 'styled-components';
import { Tabs } from 'components/Tabs/Tabs';
import { EditDetails } from 'profile/editApplicant/EditDetails';
import { EditStatus } from 'profile/editApplicant/EditStatus';
import { ProductLevel } from 'permissions/constants/ProductLevels';
import { UserGroup } from 'permissions/constants/Roles';
import { DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useApplicant } from 'contexts/ApplicantContext';
import Loader from 'components/Loader';
import { usePermissions } from 'contexts/PermissionContext';
import { useSchool } from 'contexts/SchoolContext';

const TabsWrapper = styled.div`
  height: 5rem;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 2rem;
`;

export const EditApplicant = ({ onUpdate = () => {}, onToggle, defaultTab = 0 }) => {
  const {
    state: { applicant },
  } = useApplicant();
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const { hasGroup } = usePermissions();
  const {
    state: { school },
  } = useSchool();
  const productLevel = school?.product_level;

  const tabs =
    hasGroup([UserGroup.Institutional]) && productLevel !== ProductLevel.EnrollerRecruit ? [{ label: 'Details' }, { label: 'Status' }] : [{ label: 'Details' }];

  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Edit applicant
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onToggle}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {!applicant ? (
          <Loader center />
        ) : (
          <>
            <TabsWrapper>
              <Tabs tabs={tabs} active={currentTab} onClick={(v) => setCurrentTab(v)} />
            </TabsWrapper>

            {currentTab === 0 && <EditDetails onToggle={onToggle} />}
            {currentTab === 1 && <EditStatus onToggle={onToggle} onUpdate={onUpdate} />}
          </>
        )}
      </DialogContent>
    </>
  );
};
