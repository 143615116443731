import { Typography } from '@mui/material';
import { useSchool } from 'contexts/SchoolContext';

export const DefaultPaymentOption = (props) => {
  const {
    state: { school },
  } = useSchool();
  const country = school?.country;

  return <Typography>{props.onlyOption ? 'Pay this Invoice via Telegraphic Bank Transfer' : `I am paying within ${country?.name || ''}`}</Typography>;
};
