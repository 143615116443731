import { MobileMenu } from 'components/MobileMenu/MobileMenu';
import { mainNav } from 'config/routes';
import { ProfileMenu } from 'components/Menu/ProfileMenu';
import { LocaleMenu } from 'components/Menu/LocaleMenu';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

export const UserMenu = ({ hideLogin = false }) => {
  const { slug } = useParams();

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: '100%' }}>
      <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
        <ProfileMenu {...{ hideLogin, slug }} />
        <LocaleMenu />
      </Stack>
      {slug && <MobileMenu mainNav={mainNav} localeChooser={<LocaleMenu />} />}
    </Stack>
  );
};
