import { useApplication } from '../../../contexts/ApplicationContext';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from '../../../app/routes/SchoolRoutes';
import { PropsWithChildren, useEffect, useState } from 'react';
import { FormBuilderField } from '../types/FormBuilderField';
import { useStatelessGet } from '../../../hooks/useStatelessGet';
import { ResponseEnvelope } from '../../../types/ResponseEnvelope';
import { ApiFormBuilderStep } from '../../Workflow/WorkflowFormBuilder';
import { convertApiFieldsToBuilderFields } from '../utils/convertApiFieldsToBuilderFields';
import { getValuesFromFormData } from '../FormBuilder';
import { FormField } from '../FormField';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { displayWidthToGrid } from '../utils/displayWidthToGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useNotificationMessages } from 'hooks/useNotificationMessages';

enum ReviewStatus {
  UNREVIEWED = 'unreviewed',
  REVIEWING = 'reviewing',
  ENACTED = 'enacted',
  APPROVED = 'approved',
}

type ReviewStepFieldValue = {
  status: ReviewStatus;
  fields: Map<string, { comment: string }>;
};

interface ReviewStepFieldProps {
  step: string;
  control: any;
  value: ReviewStepFieldValue;
  onChange: (value: ReviewStepFieldValue) => void;
}

interface FieldWithReviewProps extends PropsWithChildren {
  field: FormBuilderField;
  fieldComment: string;
  reviewStatus: ReviewStatus;
  handleCommentChange: (fieldId: string, comment: string) => void;
}

const FieldWithReview = ({ field, fieldComment, reviewStatus, handleCommentChange, children }: FieldWithReviewProps) => {
  const fieldsToExclude = [
    'content',
    'reviewstep',
    'documents',
    'applicantdetails',
    'accountsummary',
    'paymentoptions',
    'payment',
    'separator',
    'stepsummary',
    'templatedisplay',
    'infobox',
  ];
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const initialCommentValue = fieldComment ?? '';
  const [comment, setComment] = useState(initialCommentValue);

  const { showSuccessMessage } = useNotificationMessages();

  const iconButtonStyles = { borderRadius: '9px', color: theme.palette.secondary.main, border: `1px solid ${theme.palette.grey[300]}` };

  const handleSaveComment = () => {
    handleCommentChange(field.slug, comment);
    setOpen(false);
  };

  const handleDeleteComment = () => {
    handleCommentChange(field.slug, '');
    setComment('');
    setIsDeleteDialogOpen(false);
    setOpen(false);
  };

  const handleCloseReviewBox = () => {
    setOpen(false);
    setComment(initialCommentValue);
  };

  const handleApproveChange = () => {
    handleCommentChange(field.slug, '');
    setComment('');
    showSuccessMessage('Change approved!');
  };

  const gridItemProps = { xs: 12, lg: displayWidthToGrid[field.displayWidth] };

  if (fieldsToExclude.includes(field.type)) {
    return <Grid {...gridItemProps}>{children}</Grid>;
  }

  const reviewBox = (
    <ClickAwayListener onClickAway={handleCloseReviewBox}>
      <Stack direction="column">
        <FormControl>
          <FormLabel>Review comment</FormLabel>
          <TextField size="small" multiline rows={3} onChange={(event) => setComment(event.target.value)} value={comment} />
        </FormControl>
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <IconButton title="Delete comment" sx={iconButtonStyles} onClick={() => setIsDeleteDialogOpen(true)}>
            <DeleteIcon />
          </IconButton>
          <Stack direction="row" gap={1}>
            <IconButton title="Close" sx={iconButtonStyles} onClick={handleCloseReviewBox}>
              <CloseIcon />
            </IconButton>
            <IconButton
              title="Save review comment"
              sx={{
                ...iconButtonStyles,
                backgroundColor: theme.palette.primary.main,
                border: 'none',
                color: theme.palette.primary.contrastText,
                '&:hover': { backgroundColor: theme.palette.primary.dark },
              }}
              onClick={handleSaveComment}
            >
              <DoneIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </ClickAwayListener>
  );

  return (
    <Grid {...gridItemProps}>
      <Tooltip
        open={open}
        arrow
        placement="top"
        title={reviewBox}
        componentsProps={{
          tooltip: {
            sx: {
              padding: 2,
              border: `2px solid ${theme.palette.primary.main}`,
              borderRadius: '9px',
              bgcolor: theme.palette.background.paper,
              '& .MuiTooltip-arrow': {
                color: 'white',
                '&:before': {
                  border: `2px solid ${theme.palette.primary.main}`,
                },
              },
            },
          },
        }}
        slotProps={{
          // Prevents tooltip from closing when clicking inside of it.
          popper: {
            disablePortal: true,
            onClick: (e) => {
              e.stopPropagation();
            },
          },
        }}
      >
        <Stack gap={1}>
          <Box
            position="relative"
            sx={{
              cursor: 'pointer',
              transition: 'background 0.3s ease-in',
              borderRadius: '9px',
              '&:hover': {
                background: theme.palette.grey[200],
              },
            }}
            onClick={() => setOpen(true)}
          >
            {children}

            <Box
              sx={{
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                background: 'rgba(0, 0, 0, 0)',
              }}
            ></Box>
          </Box>
          {fieldComment && (
            <>
              <Typography variant="caption" color="success.main" pl={2}>
                {fieldComment}
              </Typography>
              <Stack direction="row" gap={1}>
                <Button onClick={() => setOpen(true)} variant="outlined" color="secondary" startIcon={<QuestionAnswerIcon />}>
                  Edit comment
                </Button>
                {reviewStatus === ReviewStatus.ENACTED && (
                  <Button onClick={handleApproveChange} variant="contained" color="primary" startIcon={<DoneIcon />}>
                    Approve change
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Stack>
      </Tooltip>
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)} sx={{ zIndex: 2000 }}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Typography variant="h4" component="h2">
              Delete comment
            </Typography>
            <IconButton aria-label="close" title="Close" onClick={() => setIsDeleteDialogOpen(false)} color="secondary">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this comment?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteComment} color="error">
            Delete
          </Button>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export const ReviewStepField = ({ step, control, value, onChange }: ReviewStepFieldProps) => {
  const {
    state: { application },
  } = useApplication();
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const sourceUrl = `/schools/${schoolSlug}/applications/${application?.id}/stages/${application?.current_stage_id}/steps/${step}`;
  const [fields, setFields] = useState<FormBuilderField[]>([]);
  const [values, setValues] = useState<Record<string, any>>(new Map());
  const getFields = useStatelessGet<ResponseEnvelope<ApiFormBuilderStep>>(sourceUrl);

  useEffect(() => {
    if (application) {
      getFields().then((response) => {
        if (response.data.fields) {
          const fieldsResponse = convertApiFieldsToBuilderFields(response.data.fields);
          setValues(getValuesFromFormData(fieldsResponse, application?.custom_questions || {}, application?.custom_question_answers || {}));
          setFields(fieldsResponse);
        }
      });
    }
  }, [application, getFields]);

  const handleCommentChange = (fieldId, comment) => {
    const newValue = { ...value };
    newValue.fields.set(fieldId, { comment });
    onChange(newValue);
  };

  const reviewStatus = value.status;

  return (
    <Grid container justifyContent="center" spacing={3}>
      {fields.map((field) => {
        const fieldComment = value.fields.get(field.slug)?.comment ?? '';
        return (
          <FormField
            key={field.slug}
            value={values[field.slug]}
            fields={fields}
            field={field}
            control={control}
            readOnly
            fieldWrapper={(children) => (
              <FieldWithReview field={field} key={field.slug} fieldComment={fieldComment} reviewStatus={reviewStatus} handleCommentChange={handleCommentChange}>
                {children}
              </FieldWithReview>
            )}
          />
        );
      })}
    </Grid>
  );
};
