import { ReactComponent as IconError } from 'images/icons/Material/error.svg';
import { intlFormat, parseISO } from 'date-fns';
import paymentProviderMap from 'payment/paymentProviderMap';
import { PAYMENT_STATUS_ADJUSTMENT } from 'invoices/InvoiceStatuses';
import { getOverpaymentsFormatted, getReceiptPaymentsFormatted, getReceivedTotal } from '../invoices/invoiceUtils';
import currency from '../currency/currency';
import { Box, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useLocale } from 'contexts/LocaleContext';
import { useSchool } from '../contexts/SchoolContext';
import Loader from '../components/Loader';

export const PaymentsReceived = ({ payments }) => {
  const { locale, localeCode } = useLocale();
  const {
    state: { school },
  } = useSchool();

  if (!school) return <Loader />;

  const paymentsFormatted = getReceiptPaymentsFormatted(payments, school.currency_code, locale);
  const overpaymentsFormatted = getOverpaymentsFormatted(payments, school.currency_code, locale);
  const totalReceivedAmount = currency(getReceivedTotal(payments, school.currency_code, locale), school.currency_code, locale);

  return (
    <Box overflow={{ xs: 'auto', md: 'hidden' }}>
      <Typography variant="h5" component="h3" mb={2}>
        Payment(s) Received
      </Typography>
      <Table data-cy="payments-received">
        <TableHead>
          <TableRow>
            <TableCell width="15%">Date</TableCell>
            <TableCell width="70%">Description</TableCell>
            <TableCell width="15%">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentsFormatted.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} sx={{ fontSize: 'large' }}>
                No payments received
              </TableCell>
            </TableRow>
          )}
          {paymentsFormatted.map((payment) => {
            const paymentProvider = paymentProviderMap[payment.payment_provider.slug];
            const Icon = paymentProvider.iconSmall;
            return (
              <TableRow key={payment.id}>
                <TableCell width="15%" data-cy="payment-date-received">
                  <Typography>{payment.payment_date ? intlFormat(parseISO(payment.payment_date), { locale: localeCode }) : null}</Typography>
                </TableCell>
                <TableCell width="70%" data-cy="payment-description">
                  <Tooltip title={payment.payment_provider.name}>
                    <Stack direction="row" alignItems="center">
                      <Icon className="icon icon--payment" />
                      <Typography>{payment.status === PAYMENT_STATUS_ADJUSTMENT ? 'Adjustment' : `${payment.payment_provider.name} payment`}</Typography>
                    </Stack>
                  </Tooltip>
                </TableCell>
                <TableCell width="15%" data-cy="payment-amount-received">
                  <Typography>{payment.amount}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
          {overpaymentsFormatted.map((payment) => (
            <TableRow key={payment.id} sx={{ opacity: 0.6 }}>
              <TableCell width="15%">
                <Typography>{payment.payment_date ? intlFormat(parseISO(payment.payment_date), { locale: localeCode }) : null}</Typography>
              </TableCell>
              <TableCell width="70%">
                <Tooltip title={payment.payment_provider.name}>
                  <Stack direction="row" alignItems="center">
                    <IconError className="icon icon--payment" />
                    <Typography>Overpayment</Typography>
                  </Stack>
                </Tooltip>
              </TableCell>
              <TableCell width="15%">
                <Typography>{payment.amount}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ fontSize: 'large', fontWeight: 'bold', textAlign: 'right' }} colSpan={2}>
              Total
            </TableCell>
            <TableCell sx={{ fontSize: 'large' }}>{totalReceivedAmount}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
};
