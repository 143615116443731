import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, useTheme } from '@mui/material';
import defaultLogo from 'images/icons/favicon-180.png';
import { useSchool } from 'contexts/SchoolContext';

const Logo = styled.img`
  max-height: 100%;
  width: auto;
`;

export const MobileMenuHeader = ({ onClose }) => {
  const {
    state: { school },
  } = useSchool();
  const theme = useTheme();
  const schoolName = school?.name || '';
  const schoolColor = school?.color || theme.palette.primary.main;
  const logoSrc = school?.logo?.preview || defaultLogo;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ backgroundColor: schoolColor, height: '7rem' }}>
      <Logo src={logoSrc} alt={`${schoolName} logo`} height="57" />
      <IconButton
        aria-label="close"
        title="Close"
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
