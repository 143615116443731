import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Pagination, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import CloseIcon from '@mui/icons-material/Close';

import 'react-pdf/dist/esm/Page/TextLayer.css';

// Needed for PDF viewer
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

interface FilePreviewDialogProps {
  type: string;
  fileBlob: Blob;
  fileName: string;
  open: boolean;
  onToggle: () => void;
}

export const FilePreviewDialog = ({ type, fileBlob, fileName, open, onToggle }: FilePreviewDialogProps) => {
  const file = new File([fileBlob], fileName);
  const fileUrl = URL.createObjectURL(fileBlob);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const isImage = type === 'image';
  const isPdf = type === 'pdf';

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onToggle}
      PaperProps={{
        style: {
          width: 'auto',
          maxWidth: '80%',
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            {isImage ? 'Picture' : 'Document'} preview
          </Typography>
          <IconButton aria-label="close" title="Close" onClick={onToggle} color="secondary">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {isImage && <Box component="img" src={fileUrl} />}
        {isPdf && (
          <Document file={file} onLoadSuccess={handleDocumentLoadSuccess}>
            <Page pageNumber={currentPage} renderTextLayer={false} renderAnnotationLayer={false} />
          </Document>
        )}
      </DialogContent>
      {isPdf && (
        <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
          <Pagination count={numPages} color="primary" onChange={(e, page) => setCurrentPage(page)} />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default FilePreviewDialog;
