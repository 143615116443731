import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PROFILE_TYPES_MAP } from 'enquiries/constants/FieldSources';
import { Card, Chip, ListItem, Slide, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMountEffect } from 'hooks/useMountEffect';

const decelerationCurve = 'cubic-bezier(0.0, 0.0, 0.2, 1)';

const Name = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 1.5rem;
  color: #000000;
  letter-spacing: 0;
  line-height: 1.95rem;
  white-space: normal;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
`;

const Task = styled.small`
  font-weight: 300;
  font-size: 1.4rem;
  color: #414141;
  letter-spacing: 0;
  line-height: 2rem;
  margin-top: 0.8rem;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
`;

const Status = styled.small`
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #9e9e9e;
  letter-spacing: 0.15rem;
  margin-top: 0.8rem;
  opacity: 0;
  transform: translate3d(25%, 0, 0);
`;

export const Reminder = ({ statusColour, delay, link, name, role, status, due }) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const [slideChip, setSlideChip] = useState(false);

  useMountEffect(() => {
    setTimeout(() => setSlideChip(true), 930);
  });

  return (
    <ListItem
      className="animated animated--onload anim__slide--up"
      ref={containerRef}
      sx={{
        p: 0,
        width: '100%',
        opacity: 0,
        transform: 'translate3d(0, 100%, 0)',
        transition: `transform 260ms ${decelerationCurve}, opacity 220ms ${decelerationCurve}`,
        animationDelay: `${delay}ms`,
        borderLeft: `0.3rem solid ${theme.palette[statusColour].main}`,
      }}
    >
      <Card sx={{ p: 2, flex: 1, '&:hover': { backgroundColor: 'secondary.light' } }}>
        <Link to={link}>
          <Grid container sx={{ width: '100%' }}>
            <Grid xs={6} sx={{ pb: 2 }}>
              <Name className="animated animated--onload animated--delayed3 anim__slide--up">{name}</Name>
            </Grid>
            <Grid xs={6} display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
              <Slide in={slideChip} direction="left" container={containerRef.current}>
                <Chip color={statusColour} label={status} size="small" />
              </Slide>
            </Grid>
            <Grid xs={6}>
              <Task className="animated animated--onload animated--delayed4 anim__slide--up">{PROFILE_TYPES_MAP[role]}</Task>
            </Grid>
            <Grid xs={6} display="flex" justifyContent="flex-end">
              <Status className="animated animated--onload animated--delayed5 anim__slide--rightin">{due}</Status>
            </Grid>
          </Grid>
        </Link>
      </Card>
    </ListItem>
  );
};
