import { useCallback, useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { InvoiceTable } from 'invoices/InvoiceTable';
import { AddPayment } from 'payment/components/AddPayment';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useGet } from 'hooks/useGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { getErrorMessage } from 'utils/errors';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { intlFormat, parseISO } from 'date-fns';
import { useLocale } from '../contexts/LocaleContext';

export const Invoice = ({ invoiceId, heading = '', canAddPayments = false, asStatement, isSharedInvoice = false }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [loading, getInvoice] = useGet<ResponseEnvelope<any>>(`/schools/${schoolSlug}/invoices/${invoiceId}`, {
    skipAuth: isSharedInvoice,
  });
  const { showErrorMessage } = useNotificationMessages();
  const { localeCode } = useLocale();
  const [invoice, setInvoice] = useState<any>();

  const fetchInvoice = useCallback(async () => {
    try {
      const response = await getInvoice();
      setInvoice(response.data);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getInvoice, showErrorMessage]);

  useEffect(() => {
    if (invoiceId) {
      fetchInvoice();
    }
  }, [fetchInvoice, invoiceId]);

  if (invoice === undefined || loading) {
    return <Loader center />;
  }

  const renderInvoiceDetails = heading === 'Invoice details';

  const canAddManualPayment = canAddPayments && !invoice.xero_id;
  const canAddXeroPayment = canAddPayments && invoice.xero_id;

  const renderOpenXeroButton = Boolean(invoice?.xero_url?.xeroUrl);
  const invoiceDate = invoice.created_at ? intlFormat(parseISO(invoice.created_at), { locale: localeCode }) : '-';
  const invoiceDueDate = invoice.due_date ? intlFormat(parseISO(invoice.due_date), { locale: localeCode }) : '-';
  const invoiceNumber = invoice.invoice_number ?? '-';
  return (
    <Box bgcolor="paper" mb={4}>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4" component="h2">
          {heading}
        </Typography>
        {renderInvoiceDetails && (
          <Stack spacing={1} mb={2}>
            <Stack direction="row" justifyContent="space-between" mr={1} spacing={1}>
              <Typography fontWeight="bold">Invoice date: </Typography>
              <Typography>{invoiceDate}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" mr={1} spacing={1}>
              <Typography fontWeight="bold">Due date: </Typography>
              <Typography>{invoiceDueDate}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" mr={1} spacing={1}>
              <Typography fontWeight="bold">Invoice number: </Typography>
              <Typography>{invoiceNumber}</Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <InvoiceTable disabled asStatement={asStatement} canAddPayments={canAddPayments} onDeletePayment={fetchInvoice} invoice={invoice} />
      {canAddManualPayment && <AddPayment onPayment={fetchInvoice} invoice={invoice} />}
      {canAddXeroPayment && (
        <>
          <Typography component="h3" my={2}>
            Payments for this invoice are managed in Xero
          </Typography>
          {renderOpenXeroButton && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: 1 }}
              onClick={() => {
                const xeroWindow = window.open(invoice.xero_url.xeroUrl, '_blank');
                xeroWindow?.focus();
              }}
            >
              View Invoice in Xero
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
