import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { useGet } from '../../hooks/useGet';
import { ResponseEnvelope } from '../../types/ResponseEnvelope';
import { useNotificationMessages } from '../../hooks/useNotificationMessages';
import { getErrorMessage } from '../../utils/errors';
import { usePut } from '../../hooks/usePut';
import { LoadingButton } from '@mui/lab';

const LoaderSpacer = styled.div`
  display: flex;
  align-items: center;
  min-height: 30rem;
`;

export const TransferOwnershipAgentsTable = ({ applicantId, loadApplicant, applicantOwnerId }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [agents, setAgents] = useState<any[]>([]);
  const [loadingGetAgents, getAgents] = useGet<ResponseEnvelope<any[]>>(`/schools/${schoolSlug}/users`);
  const [loadingTransferOwnership, putTransferOwnership] = usePut<ResponseEnvelope<any[]>>(`/schools/${schoolSlug}/applicants/${applicantId}/owner`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const fetchAgents = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      params.set('type', 'Agent');
      params.set('active', '1');
      const response = await getAgents(params);
      setAgents(response.data);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getAgents, showErrorMessage]);

  const transferOwnership = useCallback(
    async (agent) => {
      try {
        const response = await putTransferOwnership({
          agent_id: agent.id,
        });
        loadApplicant();
        showSuccessMessage(response.message);
      } catch (error) {
        showErrorMessage(getErrorMessage(error));
      }
    },
    [loadApplicant, putTransferOwnership, showErrorMessage, showSuccessMessage],
  );

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Agency</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const renderTransferButton = (agent) => {
    if (applicantOwnerId === agent.id) {
      return 'Owner';
    }

    return (
      <LoadingButton variant="contained" loading={loadingTransferOwnership} onClick={() => transferOwnership(agent)}>
        Make owner
      </LoadingButton>
    );
  };

  const renderTableRows = () => {
    if (!agents.length) {
      return <></>;
    }

    return agents.map((agent, index) => {
      const agencyName = agent.additional_details && agent.additional_details.agency_name ? agent.additional_details.agency_name : '';

      return (
        <TableRow key={`row-${index}`}>
          <TableCell>
            {agent.first_name} {agent.last_name}
          </TableCell>
          <TableCell>{agent.email || ''}</TableCell>
          <TableCell>{agencyName}</TableCell>
          <TableCell>{renderTransferButton(agent)}</TableCell>
        </TableRow>
      );
    });
  };

  if (!agents.length && loadingGetAgents) {
    return (
      <LoaderSpacer>
        <Loader center />
      </LoaderSpacer>
    );
  }

  return (
    <Stack alignItems="center">
      <Table>
        {renderTableHeader()}
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
    </Stack>
  );
};
