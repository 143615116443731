import React from 'react';
import styled from 'styled-components';
import Country from 'components/Country/Country';
import EmptyState from 'components/Profile/EmptyState';
import CUSTOM_ORIGIN_MAP from 'enquiries/constants/CustomOriginMap';
import { Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const Spacer = styled.div`
  padding: 4rem 0;
`;

export const LinkEnquiriesTable = ({ leads, selectedLead, onSelect }) => {
  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Origin</TableCell>
          <TableCell>Country</TableCell>
          <TableCell>Phone number</TableCell>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const renderTableRows = () => {
    return leads.map((enquiry, index) => {
      const { country } = enquiry;
      const OriginLogo = CUSTOM_ORIGIN_MAP[enquiry.origin.key] ? CUSTOM_ORIGIN_MAP[enquiry.origin.key].LogoDark : null;
      const origin = OriginLogo ? <OriginLogo width="150" title={enquiry.origin.name} /> : enquiry.origin.name;

      return (
        <TableRow key={`row-${index}`}>
          <TableCell>
            <RadioGroup value={selectedLead} onChange={(_, id) => onSelect(id)}>
              <Radio value={enquiry.id} />
            </RadioGroup>
          </TableCell>
          <TableCell>
            {enquiry.first_name} {enquiry.last_name}
          </TableCell>
          <TableCell>{origin}</TableCell>
          <TableCell>
            <Country name={country.name} code={country.iso_3166_2.toLowerCase()} />
          </TableCell>
          <TableCell>{enquiry.contact_number || null}</TableCell>
          <TableCell>{enquiry.email || null}</TableCell>
        </TableRow>
      );
    });
  };

  if (!leads.length) {
    return (
      <Spacer>
        <EmptyState nounSingular="enquiry" nounPlural="enquiries" />
      </Spacer>
    );
  }

  return (
    <Table>
      {renderTableHeader()}
      <TableBody>{renderTableRows()}</TableBody>
    </Table>
  );
};
