import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { interleaveMenuDivider } from 'utils/interleaveDivider';
import { usePermissions } from 'contexts/PermissionContext';
import { greenheartStaffRoles } from 'permissions/constants/Roles';
import { School } from '../../types/School';
import { useSchool } from '../../contexts/SchoolContext';

export const SchoolSelectorDropdownButton = () => {
  const navigate = useNavigate();
  const {
    state: { schools },
  } = useSchool();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { hasRole } = usePermissions();
  const isGreenheartStaff = hasRole(greenheartStaffRoles);

  const handleClickActiveSchool = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!hasMultipleSchools) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectSchool = (school: School) => () => {
    handleClose();
    const homeUrl = isGreenheartStaff ? 'participants' : 'pipeline';
    if (school.external) {
      window.location.href = `${school.domain}/${school.slug}/${homeUrl}`;
    } else {
      const next = new URLSearchParams(window.location.search).get('next') || homeUrl;
      navigate(`/${school.slug}/${next}`);
    }
  };

  const hasMultipleSchools = schools.length > 1;
  const filteredSchools = useMemo(() => schools.filter((school) => school.name.toLowerCase().includes(searchTerm.toLowerCase())), [schools, searchTerm]);

  if (!hasMultipleSchools) return <></>;

  return (
    <>
      <IconButton
        id="school-selector"
        aria-controls={open ? 'school-selector-menu' : undefined}
        aria-haspopup={hasMultipleSchools}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickActiveSchool}
        sx={{ color: (theme) => theme.palette.grey[500] }}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        id="school-selector-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
          'aria-labelledby': 'school-selector',
        }}
      >
        <Stack px={2} mb={1} component="li" key="provider-search-box">
          <Typography variant="subtitle2">Change Provider:</Typography>
          <TextField
            fullWidth
            autoFocus
            size="small"
            placeholder="Search..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            InputProps={{
              endAdornment: (
                <IconButton sx={{ visibility: searchTerm ? 'visible' : 'hidden' }} onClick={() => setSearchTerm('')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Stack>
        {interleaveMenuDivider(
          filteredSchools.map((school: School, index) => (
            <MenuItem key={school.id} onClick={handleSelectSchool(school)} tabIndex={index}>
              <Stack direction="row" alignItems="center" spacing={2} pr={1}>
                <Avatar
                  src={school.logo.preview}
                  alt={`${school.name} logo`}
                  variant="square"
                  sx={{
                    bgcolor: school.color,
                    width: 24,
                    height: 24,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AccountBalanceIcon sx={{ width: '20px', height: '20px' }} />
                </Avatar>
                <Typography>{school.name}</Typography>
              </Stack>
            </MenuItem>
          )),
        )}
      </Menu>
    </>
  );
};
