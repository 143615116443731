import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { useGet } from 'hooks/useGet';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { getErrorMessage } from 'utils/errors';
import { FieldError } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import Loader from 'components/Loader';

type DropdownOptions = { label: string; value: string }[];

interface ApiDropdownProps {
  label: string;
  disabled: boolean;
  value: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  apiPath: string;
}

export const ApiDropdownField = ({ label, disabled, value, onChange, error, name, readOnly, apiPath }: ApiDropdownProps) => {
  const [loading, getOptions] = useGet<DropdownOptions>(apiPath);
  const { showErrorMessage } = useNotificationMessages();
  const [fetchError, setFetchError] = useState(false);
  const [options, setOptions] = useState<DropdownOptions>([]);
  const hasEmptyOption = useMemo(() => options.some((s) => s.value === ''), [options]);

  const fetchOptions = useCallback(async () => {
    try {
      const response = await getOptions();
      setOptions(response);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
      setFetchError(true);
    }
  }, [getOptions, showErrorMessage]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="external-dropdown">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        {loading && <Loader small center />}
        {fetchError && <Typography color="error">Could not load options</Typography>}
        {!loading && !fetchError && (
          <Select
            displayEmpty
            size="small"
            variant="outlined"
            value={value}
            name={name}
            error={!!error}
            aria-invalid={!!error}
            labelId={`${name}-label`}
            disabled={disabled}
            onChange={(event) => onChange(event.target.value)}
            readOnly={readOnly}
          >
            {!hasEmptyOption && (
              <MenuItem value="">
                <em>Select option</em>
              </MenuItem>
            )}
            {options.map((option) => {
              return (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default ApiDropdownField;
