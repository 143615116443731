import { AuthUser } from './user';
import { greenheartStaffRoles, UserGroup, UserRole } from '../permissions/constants/Roles';

export const getLandingPage = (
  user: AuthUser,
  hasGroup: (groups: UserGroup[]) => boolean,
  hasRole: (roles: UserRole[]) => boolean,
  schoolSlug?: string,
): string => {
  // TODO: when preferred school added use that for all
  // if we are on a school route, or the user only has 1 associated school
  const redirectSchoolSlug: string | null = schoolSlug || user.userData.schoolSlugs.length === 1 ? user.userData.schoolSlugs[0] : null;

  // admin user goes to workflow management
  if (user.userData.roles.includes(UserRole.EnrollerAdmin)) {
    return '/management/workflows';
  }

  // school based landing pages
  if (redirectSchoolSlug) {
    if (hasRole([UserRole.HostOrganizationAdmin, UserRole.HostOrganizationStaff])) {
      return `/${redirectSchoolSlug}/positions`;
    }
    const isNonInstitutionalUser = hasRole([UserRole.Agent, UserRole.Parent, UserRole.Applicant]);
    const isGreenheartStaff = hasRole(greenheartStaffRoles);
    const isGreenheartPartner = hasRole([UserRole.PartnerAdmin, UserRole.PartnerStaff]);
    if (isGreenheartStaff || isGreenheartPartner) {
      return `/${redirectSchoolSlug}/participants`;
    }
    if (isNonInstitutionalUser) {
      return `/${redirectSchoolSlug}/pipeline`;
    }
    if (hasGroup([UserGroup.Institutional])) {
      return `/${redirectSchoolSlug}/dashboard`;
    }
  }

  if (user.userData.roles.includes(UserRole.SharedApplicant)) {
    // If the shared user only has one shared application, just take them directly to it
    if (user.userData.sharedUserApplications.length === 1) {
      const sharedApplication = user.userData.sharedUserApplications[0];
      return `/${sharedApplication.school.slug}/enrolment-application/applicant/${sharedApplication.applicant_id}/application/${sharedApplication.id}/stages`;
    } else {
      return '/shared-enrolments';
    }
  }

  return '/my-schools';
};
