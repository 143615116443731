import { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import TextField from '../FormBuilder/fields/TextField';
import CheckboxField from '../FormBuilder/fields/CheckboxField';
import CheckboxsetField from '../FormBuilder/fields/CheckboxsetField';
import SchoolSubjectsSelectorField from '../FormBuilder/fields/SchoolSubjectsSelectorField';
import FileField from '../FormBuilder/fields/FileField';
import MultiLevelMultiSelectField from '../FormBuilder/fields/MultiLevelMultiSelectField/MultiLevelMultiSelectField';
import MegaSelectionMenuField from '../FormBuilder/fields/MegaSelectionMenuField';
import NumberField from '../FormBuilder/fields/NumberField';
import DatePickerField from '../FormBuilder/fields/DatePickerField';
import EmailField from '../FormBuilder/fields/EmailField';
import StandardPhoneNumberField from '../FormBuilder/fields/StandardPhoneNumberField';
import TextareaField from '../FormBuilder/fields/TextareaField';
import ExternalDropdownField from '../FormBuilder/fields/ExternalDropdownField';
import DropdownField from '../FormBuilder/fields/DropdownField';
import RadioField from '../FormBuilder/fields/RadioField';
import DocumentsField from '../FormBuilder/fields/DocumentsField';
import ApiDropdownField from './fields/ApiDropdownField';
import { getValidationRules } from './fields/getValidationRules';
import { useCountries } from 'contexts/CountriesContext';
import { Control, FieldError, useController, useWatch, UseFormTrigger, ControllerProps } from 'react-hook-form';
import { newMultiOptions } from 'forms/consumer/utils/newMultiOptions';
import CountryAndTimezoneField from '../FormBuilder/fields/CountryAndTimezoneField';
import PhoneNumberField from '../FormBuilder/fields/PhoneNumberField';
import CountryField from '../FormBuilder/fields/CountryField';
import LocalTimeField from '../FormBuilder/fields/LocalTimeField';
import SelectLocalTimeField from '../FormBuilder/fields/SelectLocalTimeField';
import { slugify } from 'utils/slugify';
import { convertTZ } from 'utils/getLocalTime';
import spacetime from 'spacetime';
import { Alert, Box, Button, Divider, Typography } from '@mui/material';
import TemplateDisplayField from '../FormBuilder/fields/TemplateDisplayField';
import TimeField from '../FormBuilder/fields/TimeField';
import MonthAndYearField from '../FormBuilder/fields/MonthAndYearField';
import StartAndEndDateField from '../FormBuilder/fields/StartAndEndDateField';
import ConditionsCheckboxsetField from '../FormBuilder/fields/ConditionsCheckboxsetField';
import PasswordField from '../FormBuilder/fields/PasswordField';
import AutocompleteField from '../FormBuilder/fields/AutocompleteField';
import ContentField from '../FormBuilder/fields/ContentField';
import TimezoneField from '../FormBuilder/fields/TimezoneField';
import ApplicantDetailsField from '../FormBuilder/fields/ApplicantDetailsField';
import ApiJsonField from '../FormBuilder/fields/ApiJsonField';
import InvoiceField from '../FormBuilder/fields/InvoiceField';
import CreateInvoiceField from '../FormBuilder/fields/CreateInvoiceField';
import AccountSummaryField from '../FormBuilder/fields/AccountSummaryField';
import { useApplication } from '../../contexts/ApplicationContext';
import PaymentOptionsField from '../FormBuilder/fields/PaymentOptionsField';
import PaymentField from '../FormBuilder/fields/PaymentField';
import CustomFieldsPlaceholderField from '../FormBuilder/fields/CustomFieldsPlaceholderField';
import StepListField from '../FormBuilder/fields/StepListField';
import StepSummaryField from '../FormBuilder/fields/StepSummaryField';
import { FormBuilderField } from './types/FormBuilderField';
import { format, intlFormat, IntlFormatFormatOptions } from 'date-fns';
import { DATE_DASH_FORMAT } from '../../app/constants/DateFormats';
import { evaluateDisplayConditions } from './utils/evaluateDisplayConditions';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from '../../utils/user';
import { useApplicant } from '../../contexts/ApplicantContext';
import {
  ApiFormBuilderField,
  CountryAndTimeZoneApiFormBuilderField,
  CreateInvoiceApiFormBuilderField,
  InvoiceApiFormBuilderField,
  LocalTimeApiFormBuilderField,
  MultiChoiceOptions,
  PaymentOptionsApiFormBuilderField,
} from './types/api/ApiFormField';
import { ReviewStepField } from './fields/ReviewStepField';
import TabsField from './fields/TabsField';
import PlacesAutocompleteField from './fields/PlacesAutocompleteField';
import ToggleButtonField from './fields/ToggleButtonField';
import ChipsBoxField from './fields/ChipsBoxField';
import { FormBuilderFieldType } from './types/FormBuilderFieldType.enum';
import { Country } from 'types/Country';
import { useLocale } from 'contexts/LocaleContext';
import ZohoSignFrameField from './fields/ZohoSignFrameField';
import { useSchool } from 'contexts/SchoolContext';
import { Applicant } from 'types/Applicant';
import { ScheduleInterviewField } from './fields/ScheduleInterview/ScheduleInterviewField';
import PictureUploaderField from './fields/PictureUploaderField';

interface BaseFieldProps {
  label: FormBuilderField['label'];
  name: FormBuilderField['slug'];
  disabled: FormBuilderField['disabled'];
  readOnly: boolean;
  value: FormBuilderField['value'];
  onChange: (...event: any[]) => void;
  error?: FieldError;
}

interface RenderedFormFieldProps {
  field: FormBuilderField;
  renderFieldStepActions?: (name: string) => ReactNode;
  fields: FormBuilderField[];
  control: Control;
  disabled: boolean;
  readOnly: boolean;
  baseFieldProps: BaseFieldProps;
  formValues: Record<string, any>;
  countries: Country[];
  multiOptions: { label: string; value: string }[];
  fieldsMap: Map<string, ApiFormBuilderField>;
  label: string;
  validationRules: ControllerProps['rules'];
  fieldValue: any;
  onChange: (...event: any[]) => void;
  applicant?: Applicant | null;
}

const RenderedFormField = ({
  field,
  baseFieldProps,
  formValues,
  countries,
  multiOptions,
  fieldsMap,
  fields,
  renderFieldStepActions,
  label,
  control,
  validationRules,
  disabled,
  readOnly,
  fieldValue,
  onChange,
  applicant,
}: RenderedFormFieldProps) => {
  const { localeCode } = useLocale();
  const {
    state: { school },
  } = useSchool();
  const { countryMap } = useCountries();
  const schoolTimezone = school?.timezone;
  const currencyCode = school?.currency_code || 'NZD';
  const applicationSettings = school?.application_settings ?? null;
  const schoolSubjects = school?.subjects ?? [];

  switch (field.type) {
    case FormBuilderFieldType.Checkbox:
      return <CheckboxField {...baseFieldProps} />;

    case FormBuilderFieldType.Text:
      return <TextField {...baseFieldProps} />;

    case FormBuilderFieldType.Number:
      return <NumberField {...baseFieldProps} min={field.options.min} max={field.options.max} />;

    case FormBuilderFieldType.DatePicker: {
      return (
        <DatePickerField
          {...baseFieldProps}
          min={field.options.min}
          minPeriod={field.options.minPeriod}
          minDirection={field.options.minDirection}
          max={field.options.max}
          maxPeriod={field.options.maxPeriod}
          maxDirection={field.options.maxDirection}
          message={field.options.warningMessage}
          messageComparison={field.options.deltaMoreOrLess}
          messageAmount={field.options.deltaAmount}
          messagePeriod={field.options.deltaPeriod}
          messageIsAfter={field.options.warningDirection === 'after'}
        />
      );
    }

    case FormBuilderFieldType.Email:
      return <EmailField {...baseFieldProps} />;

    case FormBuilderFieldType.StandardPhoneNumber: {
      // TODO: This may move to just one in the future once backend has time
      const countryFieldId: string = field.options.referencedFieldSlugs[0];
      const country = Array.isArray(formValues[countryFieldId]) ? formValues[countryFieldId][0] : formValues[countryFieldId];
      const defaultCountry = country ? countries.find((c) => c.id === country)?.iso_3166_2 : '';
      return <StandardPhoneNumberField {...baseFieldProps} defaultCountry={defaultCountry} />;
    }

    case FormBuilderFieldType.TextArea:
      return <TextareaField {...baseFieldProps} />;

    case FormBuilderFieldType.DropdownExternal:
      return <ExternalDropdownField {...baseFieldProps} sourceUrl={field.options.sourceUrl} />;

    case FormBuilderFieldType.ApiDropdown:
      return <ApiDropdownField {...baseFieldProps} apiPath={field.options.apiPath} />;

    case FormBuilderFieldType.Dropdown: {
      return <DropdownField {...baseFieldProps} options={multiOptions} />;
    }

    case FormBuilderFieldType.CheckboxSet: {
      return <CheckboxsetField {...baseFieldProps} options={multiOptions} />;
    }

    case FormBuilderFieldType.RadioGroup: {
      return <RadioField {...baseFieldProps} options={multiOptions} direction={field.options.layout} />;
    }

    case FormBuilderFieldType.ReviewStep:
      return <ReviewStepField step={field.options.step} control={control} value={fieldValue} onChange={onChange} />;

    case FormBuilderFieldType.SchoolSubjectsSelector: {
      return <SchoolSubjectsSelectorField {...baseFieldProps} options={multiOptions} subjects={schoolSubjects} />;
    }

    case FormBuilderFieldType.File: {
      return (
        <FileField
          {...baseFieldProps}
          id={field.id}
          allowMultiple={field.options.allowMultipleFiles}
          // TODO: NFI how this works, but it is equivalent to the old options behavior
          // @ts-expect-error TS(2322): The expected type comes from property 'acceptedMimeTypes' which is declared here on type 'IntrinsicAttributes & FileFieldProps'
          acceptedMimeTypes={field.options.acceptedMimeTypes?.join(',')}
          displayWidth={field.displayWidth}
        />
      );
    }

    case FormBuilderFieldType.MultiLevelMultiSelect: {
      return <MultiLevelMultiSelectField {...baseFieldProps} sourceUrl={field.options.sourceUrl} />;
    }

    case FormBuilderFieldType.DropdownMegaselect: {
      return <MegaSelectionMenuField {...baseFieldProps} sourceUrl={field.options.sourceUrl} limitation={field.options.maxChoices} />;
    }

    case FormBuilderFieldType.DocumentList:
      return <DocumentsField label={label} options={field.options.sources} settings={applicationSettings} />;

    case FormBuilderFieldType.CountryAndTimezone:
      return <CountryAndTimezoneField {...baseFieldProps} />;

    case FormBuilderFieldType.PhoneNumber:
      return <PhoneNumberField {...baseFieldProps} />;

    case FormBuilderFieldType.Country:
      return <CountryField {...baseFieldProps} />;

    case FormBuilderFieldType.LocalTime: {
      // TODO: This may move to just one in the future once backend has time
      const countryAndTimezoneFieldSlug: string = field.options.referencedFieldSlugs[0];
      const countryAndTimezoneField = fieldsMap.get(countryAndTimezoneFieldSlug) as CountryAndTimeZoneApiFormBuilderField;
      const studentTimezone = countryAndTimezoneField?.value?.[1] ?? '';
      return <LocalTimeField {...baseFieldProps} {...{ schoolTimezone, studentTimezone }} />;
    }

    case FormBuilderFieldType.SelectLocalTime: {
      const getLocalTimeLabel = (localTimeField: LocalTimeApiFormBuilderField, fieldsMap: Map<string, ApiFormBuilderField>): ReactElement => {
        const value = localTimeField.value as unknown as string[];
        if (!value || !value.length) return <></>;

        const date = value[0] ? format(value[0], DATE_DASH_FORMAT) : '';
        const time = value[1];
        const timezone = value[2];
        // TODO: This may move to just one in the future once backend has time
        const countryAndTimezoneField = fieldsMap.get(localTimeField.new_options_format.referencedFieldSlugs[0]) as CountryAndTimeZoneApiFormBuilderField;
        const countryAndTimezoneValue = countryAndTimezoneField?.value;
        const studentTZ = countryAndTimezoneValue ? countryAndTimezoneValue[1] : '';
        const dateFormat: IntlFormatFormatOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' };
        return (
          <>
            {intlFormat(convertTZ(`${date} ${time}`, timezone, studentTZ), dateFormat, { locale: localeCode })}
            <Typography>({studentTZ || spacetime.now().timezone().name})</Typography>
          </>
        );
      };
      const localTimeFieldSlugs: string[] = field.options.referencedFieldSlugs.filter((referencedFieldSlug) => {
        if (!fieldsMap.has(referencedFieldSlug)) return false;
        const localTimeField = fieldsMap.get(referencedFieldSlug)! as LocalTimeApiFormBuilderField;
        // TODO: Remove any once migrated to new endpoints which have narrowed value types per field type
        return Boolean(localTimeField.slug && localTimeField.value?.length && !localTimeField.value?.includes(null as any));
      });

      const options = localTimeFieldSlugs.map((localTimeFieldSlug) => {
        const localTimeField = fieldsMap.get(localTimeFieldSlug)! as LocalTimeApiFormBuilderField;
        const formValue = [...localTimeField.value!];
        if (formValue[0] !== null && (formValue[0] as any) instanceof Date) {
          formValue[0] = format(formValue[0], DATE_DASH_FORMAT);
        }
        return {
          key: slugify(formValue.join('-')),
          label: getLocalTimeLabel(localTimeField, fieldsMap),
          value: `${formValue}`,
        };
      });
      return <SelectLocalTimeField {...baseFieldProps} options={options} />;
    }

    case FormBuilderFieldType.TemplateDisplay: {
      return <TemplateDisplayField fields={fields} fieldsMap={fieldsMap} control={control} mapperGroup={field.options.mapperGroupId} />;
    }
    case FormBuilderFieldType.Time:
      return <TimeField {...baseFieldProps} />;

    case FormBuilderFieldType.Timezone:
      return <TimezoneField {...baseFieldProps} />;

    case FormBuilderFieldType.MonthYear:
      return <MonthAndYearField {...baseFieldProps} />;

    case FormBuilderFieldType.StartAndEndDate:
      return <StartAndEndDateField {...baseFieldProps} />;

    case FormBuilderFieldType.ConditionsCheckboxSet:
      return <ConditionsCheckboxsetField {...baseFieldProps} options={multiOptions} />;

    case FormBuilderFieldType.Password:
      return <PasswordField {...baseFieldProps} />;

    case FormBuilderFieldType.AutoComplete:
      return <AutocompleteField {...baseFieldProps} options={multiOptions} />;

    case FormBuilderFieldType.Content:
      return <ContentField value={field.value || field.options.content} />;

    case FormBuilderFieldType.ApplicantDetails:
      const { first_name, last_name, gender, date_of_birth, nationality } = applicant || {};
      return <ApplicantDetailsField firstName={first_name} lastName={last_name} gender={gender} dateOfBirth={date_of_birth} nationality={nationality?.name} />;

    case FormBuilderFieldType.ApiJson:
      return <ApiJsonField {...baseFieldProps} />;

    case FormBuilderFieldType.Invoice:
      const createInvoiceField = fieldsMap.get(field.options.createInvoiceSlug) as CreateInvoiceApiFormBuilderField;
      return <InvoiceField label={field.label} canAddPayments={field.options.managePayments || false} invoiceId={createInvoiceField!.value!} />;

    case FormBuilderFieldType.CreateInvoice:
      return (
        <CreateInvoiceField
          control={control}
          validationRules={validationRules}
          label={label}
          name={field.slug}
          disabled={disabled}
          invoiceId={field.options.invoiceId}
          readOnly={readOnly}
        />
      );

    case FormBuilderFieldType.AccountSummary: {
      const createInvoiceField = fieldsMap.get(field.options.referencedFieldSlug)! as CreateInvoiceApiFormBuilderField;
      return (
        <AccountSummaryField
          control={control}
          validationRules={validationRules}
          label={label}
          name={field.slug}
          disabled={disabled}
          readOnly={readOnly}
          invoiceId={createInvoiceField.value}
          currencyCode={currencyCode}
        />
      );
    }

    case FormBuilderFieldType.PaymentOptions: {
      const createInvoiceField = fieldsMap.get(field.options.createInvoiceSlug)! as CreateInvoiceApiFormBuilderField;
      return <PaymentOptionsField value={fieldValue} onChange={onChange} invoiceId={createInvoiceField.value!} readOnly={readOnly} />;
    }

    case FormBuilderFieldType.Payment: {
      // TODO: This may move to just one in the future once backend has time
      const paymentOptionFieldId: string = field.options.referencedFieldSlugs[0];
      const paymentOptionField = fieldsMap.get(paymentOptionFieldId)! as PaymentOptionsApiFormBuilderField;
      const invoiceFieldId = paymentOptionField.new_options_format.createInvoiceSlug;
      const invoiceField = fieldsMap.get(invoiceFieldId)! as InvoiceApiFormBuilderField;
      const selectedPaymentMethodId = paymentOptionField.value!;
      return <PaymentField invoiceId={invoiceField.value!} selectedPaymentMethodId={selectedPaymentMethodId} />;
    }

    case FormBuilderFieldType.CustomFieldsPlaceholder:
      return <CustomFieldsPlaceholderField control={control} label={label} disabled={disabled} validationRules={validationRules} readOnly={readOnly} />;

    case FormBuilderFieldType.StepList:
      return <StepListField />;

    case FormBuilderFieldType.Separator:
      return <Divider data-cy="separator" sx={{ mb: 5 }} />;
    case FormBuilderFieldType.StepSummary:
      return <StepSummaryField stepIds={field.options.stepIds} control={control} />;

    case FormBuilderFieldType.ActionSlot:
      // If renderFieldStepActions is null it means that the action is being rendered inside
      // another step e.g. stepsummary, which means we need to render a disabled action.
      return (
        <>
          {renderFieldStepActions ? (
            renderFieldStepActions(field.options.name)
          ) : (
            <Button variant="contained" disabled>
              Action disabled
            </Button>
          )}
        </>
      );
    case FormBuilderFieldType.Tabs:
      const tabQuantityFieldSlug = field.options.referencedFieldSlug;
      const tabQuantity = formValues[tabQuantityFieldSlug] ?? 0;
      return <TabsField {...baseFieldProps} tabQuantity={tabQuantity} />;

    case FormBuilderFieldType.PlacesAutocomplete: {
      let countryCode;
      if (field.options.fieldCountryScopeSlug) {
        // If the country field is on the current step we need to use formValues so we can watch for updates, otherwise we use fieldsMap
        const countryField = fieldsMap.get(field.options.fieldCountryScopeSlug);
        const countryId = formValues[field.options.fieldCountryScopeSlug] ?? (countryField?.value ? parseInt(countryField.value) : '');
        if (countryId && countryMap.has(countryId)) {
          countryCode = countryMap.get(countryId)!.iso_3166_2.toLowerCase();
        }
      } else if (field.options.iso3316CountryScope) {
        countryCode = field.options.iso3316CountryScope;
      }
      return <PlacesAutocompleteField {...baseFieldProps} restrictToCountry={countryCode} />;
    }

    case FormBuilderFieldType.ToggleButton:
      return <ToggleButtonField {...baseFieldProps} />;

    case FormBuilderFieldType.InfoBox:
      return (
        <Alert data-cy="infobox" variant="outlined" severity={field.options.type}>
          <Box dangerouslySetInnerHTML={{ __html: field.options.content }} />
        </Alert>
      );

    case FormBuilderFieldType.ChipsBox:
      return <ChipsBoxField {...baseFieldProps} />;

    case FormBuilderFieldType.ZohoSignFrame:
      return <ZohoSignFrameField id={field.id} {...baseFieldProps} />;

    case FormBuilderFieldType.ScheduleInterview:
      return <ScheduleInterviewField fieldId={field.id} {...baseFieldProps} />;

    case FormBuilderFieldType.PictureUploader: {
      return <PictureUploaderField {...baseFieldProps} id={field.id} displayWidth={field.displayWidth} aspectRatio={field.options.aspectRatio} />;
    }
    default:
      return <></>;
  }
};

interface FormFieldProps {
  field: FormBuilderField;
  fields: FormBuilderField[];
  renderFieldStepActions?: (name: string) => ReactNode;
  control: Control;
  disabled?: boolean;
  readOnly?: boolean;
  value?: any;
  trigger?: UseFormTrigger<Record<string, any>>;
  fieldWrapper?: (children: ReactElement) => ReactElement;
}

export const FormField = ({
  field,
  control,
  fields,
  renderFieldStepActions,
  disabled = false,
  readOnly = false,
  value,
  trigger,
  fieldWrapper,
}: FormFieldProps): ReactElement => {
  const {
    state: { school },
  } = useSchool();
  const accommodationTypes = school?.accommodation_types ?? [];
  const { countriesList } = useCountries();
  const { getTranslationForKey } = useLocale();
  const formValues: Record<string, any> = useWatch({ control });
  // TODO: move this
  const {
    state: { application },
  } = useApplication();
  const {
    state: { applicant },
  } = useApplicant();
  const { user } = useAuth0<AuthUser>();

  const fieldsMap = useMemo(() => {
    const map = new Map<string, ApiFormBuilderField>();
    application?.workflow.stages.forEach((stage) => {
      stage.steps.forEach((step) => {
        step.fields.forEach((f) => map.set(f.slug, f));
        // TODO: Remove once all field references have been changed from ids to slugs
        step.fields.forEach((f) => map.set(f.id, f));
      });
    });

    return map;
  }, [application?.workflow.stages]);

  // TODO: Remove RadioGroup branching logic after ER-4884
  // const multiOptions = field.options['choices'] ? newMultiOptions(field.options['choices'], accommodationTypes, formValues): [];
  let multiOptions: { label: string; value: string }[] = [];
  if (field.type === FormBuilderFieldType.RadioGroup && field.legacyOptions) {
    multiOptions = newMultiOptions(field.legacyOptions[0] as MultiChoiceOptions['choices'], accommodationTypes, formValues);
  } else if (field.options['choices']) {
    multiOptions = newMultiOptions(field.options['choices'], accommodationTypes, formValues);
  }

  let shouldDisplay = false;
  let conditionsError: string | false = false;
  try {
    shouldDisplay = evaluateDisplayConditions(field.slug, field.conditions, user, applicant, fieldsMap, formValues, school);
  } catch (error) {
    conditionsError = `${error}`;
  }

  // set disabled flag to remove from form submission
  field.disabled = !shouldDisplay || readOnly;

  const applicationFieldMessagesRules = field.applicationFieldMessages.map((fieldMessage) => ({
    type: 'changed' as const,
    message: fieldMessage.message,
  }));

  const validationRules = field.disabled
    ? {}
    : getValidationRules(fieldsMap, [...field.validationRules, ...applicationFieldMessagesRules], field.label, field.value);

  // If applicationFieldMessages has a value, it means that we the user is filling a step that has previously been reviewed,
  // so we need to trigger validation errors on page load.
  useEffect(() => {
    if (field.applicationFieldMessages.length) {
      trigger?.(field.slug);
    }
  }, [field, trigger]);

  const {
    field: { value: controlledValue, onChange },
    formState: { errors },
  } = useController({
    name: field.slug,
    control,
    rules: validationRules,
    disabled: disabled || !shouldDisplay,
  });
  if (conditionsError) return <Typography color="error">{conditionsError}</Typography>;
  if (!shouldDisplay) return <></>;

  // If the value prop is set, we assume the component is uncontrolled.
  const fieldValue = value ?? controlledValue;
  const label = getTranslationForKey(field.translationKey) || field.label;
  const baseFieldProps: BaseFieldProps = {
    label,
    name: field.slug,
    disabled,
    readOnly,
    value: fieldValue,
    onChange,
    error: errors[field.slug] as FieldError,
  };

  const renderedField = (
    <RenderedFormField
      {...{
        field,
        baseFieldProps,
        formValues,
        countries: countriesList,
        multiOptions,
        fieldsMap,
        fields,
        renderFieldStepActions,
        label,
        control,
        validationRules,
        disabled,
        readOnly,
        fieldValue,
        onChange,
        applicant,
      }}
    />
  );

  if (fieldWrapper) return fieldWrapper(renderedField);
  return <>{renderedField}</>;
};
