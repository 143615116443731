import { SchoolWorkflowRouteParams } from 'app/routes/SchoolWorkflowRoutes';
import Grid from '@mui/material/Unstable_Grid2';
import { useApplicant } from 'contexts/ApplicantContext';
import { ApplicantSummary } from './ApplicantSummary';
import { Stack, Tab, Tabs } from '@mui/material';
import HasAccess from '../../permissions/HasAccess';
import { UserGroup } from '../../permissions/constants/Roles';
import { ApplyAction } from '../../profile/ApplyAction';

export interface ApplicantRouteParams extends SchoolWorkflowRouteParams {
  application: string;
}

export const ApplicantEnrolmentsHeader = ({ activeTab, setActiveTab, pipelineTabs }) => {
  const {
    state: { applicant },
  } = useApplicant();

  if (!applicant) return <></>;

  return (
    <Grid container justifyContent="center">
      <Grid lg={12} xl={10}>
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="flex-start" spacing={1}>
          <ApplicantSummary applicant={applicant} />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <HasAccess for="group" name={[UserGroup.NonInstitutional]}>
              <ApplyAction />
            </HasAccess>
          </Stack>
        </Stack>
        <Tabs value={activeTab} centered onChange={(_, newTab) => setActiveTab(newTab)}>
          {pipelineTabs.map((tab, index) => (
            <Tab
              key={`tab-${index}`}
              label={`${tab.label}${tab.counter ? ` (${tab.counter})` : ''}`}
              value={index}
              disabled={!tab.counter}
              sx={{ px: 8, fontSize: '1.1em' }}
            />
          ))}
        </Tabs>
      </Grid>
    </Grid>
  );
};
