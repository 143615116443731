import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HasAccess from 'permissions/HasAccess';
import ProductsAccess from 'permissions/ProductsAccess';
import { slugify } from 'utils/slugify';
import { useLayoutEffect, useState } from 'react';

export const MenuLink = ({ active, external, label, navChildren, titleIcon: TitleIcon, to }) => {
  const { slug } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  useLayoutEffect(() => setAnchorEl(null), [location.pathname]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const closeAndNavigate = (to: string) => () => {
    handleClose();
    navigate(to);
  };

  if (navChildren) {
    return (
      <>
        <Stack direction="row" onClick={handleClick} sx={{ cursor: 'pointer' }}>
          <Typography mr={0.5}>{label}</Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Stack>
        {open && (
          <Menu anchorEl={anchorEl} open={true} onClose={handleClose}>
            {navChildren.map((item) => {
              return (
                <HasAccess for={item.allowedFor} name={item.allowedRoleTypes} key={slugify(item.label)}>
                  <ProductsAccess allow={item.allowedProducts} disallow={item.disallowedProducts}>
                    <MenuItem key={slugify(item.label)} onClick={closeAndNavigate(`/${slug}/${item.url}`)}>
                      <ListItemText>{item.label}</ListItemText>
                    </MenuItem>
                  </ProductsAccess>
                </HasAccess>
              );
            })}
          </Menu>
        )}
      </>
    );
  }

  return external ? (
    <Link component="a" href={to} target="_blank" rel="noreferrer" sx={{ color: theme.palette.secondary.main }}>
      {TitleIcon ? (
        <Stack>
          <Tooltip title={label}>
            <TitleIcon style={{ width: '32px', height: '32px' }} />
          </Tooltip>
        </Stack>
      ) : (
        <Typography>{label}</Typography>
      )}
    </Link>
  ) : (
    <Link href={to} color="inherit">
      <Typography fontWeight={active ? 700 : 400}>{label}</Typography>
    </Link>
  );
};
