import { useLocale } from 'contexts/LocaleContext';
import { useSchool } from 'contexts/SchoolContext';
import currency from 'currency/currency';

export const Currency = ({ amount }) => {
  const { locale } = useLocale();
  const {
    state: { school },
  } = useSchool();
  const currencyCode = school?.currency_code || 'NZD';

  return <>{currency(amount, currencyCode, locale)}</>;
};
