import { useCallback } from 'react';
import { LinkEnquiriesTable } from 'enquiries/LinkEnquiriesTable';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { usePut } from '../hooks/usePut';
import { ResponseEnvelope } from '../types/ResponseEnvelope';
import { useNotificationMessages } from '../hooks/useNotificationMessages';
import { getErrorMessage } from '../utils/errors';

export const LinkWithEnquiryDialog = ({ onClose, applicationId, active, selectedLead, leads, onSelect }) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [loading, putLeadWithApplication] = usePut<ResponseEnvelope<any>>(`/schools/${schoolSlug}/applications/${applicationId}/lead`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();

  const linkLeadWithApplication = useCallback(async () => {
    try {
      const response = await putLeadWithApplication({ lead_id: selectedLead });
      showSuccessMessage(response.message);
      onClose();
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [onClose, putLeadWithApplication, selectedLead, showErrorMessage, showSuccessMessage]);

  const renderResults = () => (
    <>
      <LinkEnquiriesTable leads={leads} onSelect={onSelect} selectedLead={selectedLead} />
      <DialogActions>
        <LoadingButton variant="contained" loading={loading} onClick={linkLeadWithApplication}>
          Link applicant
        </LoadingButton>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog fullWidth maxWidth="lg" open={active} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="h4" component="h2">
            Search for an Enquiry to link this applicant profile to
          </Typography>
          <IconButton
            aria-label="close"
            title="Close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      {renderResults()}
    </Dialog>
  );
};
