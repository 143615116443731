import { MultiChoiceOptions } from 'components/FormBuilder/types/api/ApiFormField';
import { get } from 'lodash';
import { School } from 'types/School';

export const newMultiOptions = (
  options: MultiChoiceOptions['choices'],
  accommodationTypes: School['accommodation_types'],
  formFields: Record<string, any>,
): { label: string; value: string }[] => {
  // TODO: Review filter and flatmap logic after ER-4884
  return options
    .filter((o) => typeof o === 'object')
    .flatMap((option) => {
      switch (option.source) {
        case 'default':
          return {
            label: option.label || option.value,
            value: option.value,
          };

        case 'api_json': {
          const field = formFields[option.value];
          if (!field) return null;
          return option.path ? get(field.value, option.path) : field.value;
        }

        case 'accommodation_types':
          return accommodationTypes?.map(({ name: value }) => (value ? { label: value, value } : null));

        default:
          return null;
      }
    })
    .filter((v) => v);
};
