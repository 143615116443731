import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Control, useController, useFormState } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { Box, FormControl, Stack, Typography } from '@mui/material';
import { getErrorMessage } from '../../../utils/errors';
import { useNotificationMessages } from '../../../hooks/useNotificationMessages';
import { useGet } from '../../../hooks/useGet';
import { ResponseEnvelope } from '../../../types/ResponseEnvelope';
import { getInvoiceBalanceDue, getReceiptItems, getReceiptTotal } from '../../../invoices/invoiceUtils';
import { Currency } from 'currency/components/Currency';
import { PaymentsReceived } from 'accountSummary/PaymentsReceived';
import { ReceiptItems } from 'accountSummary/ReceiptItems';
import { Invoice } from 'types/Invoice';
import { useLocale } from 'contexts/LocaleContext';

interface AccountSummaryFieldFieldProps {
  control: Control;
  name: string;
  label: string;
  disabled: boolean;
  validationRules: ControllerProps['rules'];
  readOnly: boolean;
  invoiceId: string | null;
  currencyCode: string;
}

export const AccountSummaryField = ({ name, disabled, control, label, validationRules, readOnly, invoiceId, currencyCode }: AccountSummaryFieldFieldProps) => {
  const { errors } = useFormState({ control });
  const [invoice, setInvoice] = useState<Invoice | null>();

  const { locale } = useLocale();

  const { showErrorMessage } = useNotificationMessages();
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const [invoiceLoading, getInvoice] = useGet<ResponseEnvelope<Invoice>>(`/schools/${schoolSlug}/invoices/${invoiceId}`);
  const {
    field: { onChange, value },
  } = useController({ control, name, rules: validationRules, disabled });

  const fetchInvoice = useCallback(async () => {
    try {
      const response = await getInvoice();
      setInvoice(response.data);
      onChange({ items: response.data.items, id: response.data.id });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [onChange, getInvoice, showErrorMessage]);

  useEffect(() => {
    if (invoiceId) {
      fetchInvoice();
    }
  }, [fetchInvoice, invoiceId]);

  const onChangeAmount = useCallback(
    (index: number, newValue: number) => {
      value.items[index].paid_amount = newValue;
      onChange(value);
    },
    [onChange, value],
  );

  if (invoiceLoading || !invoice) {
    return <Loader center />;
  }

  const items = getReceiptItems(value.items, currencyCode, locale);
  const receiptTotal = getReceiptTotal(items, currencyCode, locale);
  const balanceDue = getInvoiceBalanceDue(invoice, currencyCode, locale);

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="account-summary">
        <Typography variant="h4" component="h2" mb={2}>
          {label}
        </Typography>
        <Box pb={10}>
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between" spacing={3}>
            <Box flex="1">
              <Typography variant="h5" component="h3" mt={0} mb={2}>
                Invoice {invoice.invoice_number}
              </Typography>
              <Box>
                <Typography display="inline" fontWeight="bold" variant="h6">
                  Total (GST inclusive):
                </Typography>
                <Typography display="inline" ml={2}>
                  <Currency amount={invoice.total} />
                </Typography>
              </Box>
            </Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography display="inline" fontWeight="bold" variant="h6">
                Balance due:
              </Typography>
              <Typography display="inline" ml={2}>
                <Currency amount={balanceDue} />
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box pb={10}>
          <PaymentsReceived payments={invoice.payments} />
        </Box>
        <ReceiptItems isDisabled={disabled || readOnly} onChangeAmount={onChangeAmount} items={items} receiptTotal={receiptTotal} payments={invoice.payments} />
      </FormControl>
      {errors[name] ? <Typography color="error">{errors[name]!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default AccountSummaryField;
