import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DocStack from 'components/DocStack/DocStack';
import { ApplicantRouteParams } from 'components/Workflow/Workflow';
import { HandleFetch } from 'components/LoadingOrError/HandleFetch';
import { Box } from '@mui/material';

const FILE_FROM_EVENT = 'event';
const FILE_FROM_UPLOAD = 'field';
const FILE_FROM_INSTITUTION = 'institution';

interface DocumentsFieldProps {
  label: string;
  options: any[];
  settings: Record<string, any> | null;
}

export const DocumentsField = ({ options, label, settings }: DocumentsFieldProps) => {
  const { slug, application: applicationId } = useParams() as ApplicantRouteParams;
  const sourceUrl = `/schools/${slug}/applications/${applicationId}/files`;
  const sourceParams = useMemo(() => new URLSearchParams({ all: '1' }), []);

  const getDocuments = (files) =>
    options.flatMap((option) => {
      switch (option.type) {
        case FILE_FROM_EVENT: {
          return files.filter((file) => file.tag === option.value);
        }
        case FILE_FROM_UPLOAD: {
          return files.filter((file) => file.pivot.slug === option.value);
        }
        case FILE_FROM_INSTITUTION: {
          if (!settings?.[option.value]) return [];
          return [
            {
              ...settings[option.value],
              directLink: true,
              filename: settings[option.value].name,
              mime: settings[option.value].type,
            },
          ];
        }
        default: {
          return [];
        }
      }
    });

  return (
    <HandleFetch small sourceUrl={sourceUrl} sourceParams={sourceParams}>
      {(response) => (
        <Box data-cy-field-type="documents">
          <DocStack title={label} fileList={getDocuments(response.data)} withLinks showTitle compact />
        </Box>
      )}
    </HandleFetch>
  );
};

// for React.lazy
export default DocumentsField;
