import { UserRole } from 'permissions/constants/Roles';

export interface AuthUser {
  email: string;
  // sub is user uuid in format "auth0|<uuid>"
  sub: string;
  userData: {
    createdAt: string;
    firstName: string;
    lastName: string;
    roles: UserRole[];
    agencyName?: string;
    contactNumber?: string;
    countryOfResidence?: string;
    primaryMarketCountryId?: number;
    contractedWithEnroller: boolean;
    contractedWithEducatius: boolean;
    schoolSlugs: string[];
    marketingSlug: string | null;
    daySchoolPricing: 'gross' | 'net' | null;
    boardingSchoolPricing: 'gross' | 'net' | null;
    // TODO: Application typing
    sharedUserApplications: any[];
  };
}

export const getUserId = (user: AuthUser) => user.sub.replace('auth0|', '');
