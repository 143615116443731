import { sharedAppStore } from 'app/store';
import { Provider } from 'react-redux';
import styled, { ThemeProvider as LegacyThemeProvider } from 'styled-components';
import legacyTheme from 'config/theme';
import { ThemeProvider } from '@mui/material';
import { theme } from 'customTheme';
import { LocaleProvider } from '../contexts/LocaleContext';
import { SharedPageHeader } from '../components/Header/SharedPageHeader';
import SharedApp from './SharedApp';
import { ErrorBoundary } from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from '../auth0/Auth0ProviderWithNavigate';
import { AppProvider } from './AppContext';
import { SchoolProvider } from 'contexts/SchoolContext';

const AppContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${(props) => props.theme.lg} {
    min-height: 60rem;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

export const SharedEnroller = () => (
  <Provider store={sharedAppStore}>
    <LocaleProvider>
      <ThemeProvider theme={theme}>
        <LegacyThemeProvider theme={legacyTheme}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <AppProvider>
                <AppContainer>
                  <SchoolProvider>
                    <SharedPageHeader />
                    <PageContainer>
                      <ErrorBoundary>
                        <SharedApp />
                      </ErrorBoundary>
                    </PageContainer>
                  </SchoolProvider>
                </AppContainer>
              </AppProvider>
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </LegacyThemeProvider>
      </ThemeProvider>
    </LocaleProvider>
  </Provider>
);
