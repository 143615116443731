import { forwardRef } from 'react';
import type {} from '@mui/lab/themeAugmentation';
import { createTheme, ThemeOptions } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { branding } from './branding';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * If adding new mixins or variables to this file remember to update theme.d.ts definitions too
 */

/**
 * @see https://mui.com/material-ui/integrations/routing/#global-theme-link
 */
const LinkBehavior = forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material-UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

// theme is split into two parts to allow second part to reference variables declared in first
const baseTheme = createTheme({
  typography: {
    // text size reset
    fontSize: 24,
    fontFamily: branding.fontFamily,
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '4.8rem',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: 'initial',
          boxShadow: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontSize: '1.8rem',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          ':hover': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3EAD47',
      light: '#C5E6C8',
      dark: '#039855',
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[600],
      light: grey[300],
      dark: grey[700],
    },
    text: {
      primary: grey[800],
    },
    background: {
      default: grey[100],
    },
    success: {
      main: green[500],
      light: green[50],
      dark: green[700],
    },
    warning: {
      main: orange[500],
      light: orange[50],
      dark: orange[700],
    },
    error: {
      main: red[500],
      light: red[50],
      dark: red[700],
    },
    info: {
      main: '#00A9E0',
      light: '#E5F7FC',
      dark: '#014361',
    },
    shadows: {
      light: '#10182808',
      dark: '#10182814',
    },
    ...branding.paletteOptions,
  },
});

const borderRadius = baseTheme.spacing(1);

// TODO: Hoist the Paper style here to all MuiPaper
const commonOverlayOverrides = {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        borderRadius,
        boxShadow: `0px 8px 8px -4px ${baseTheme.palette.shadows.light}, 0px 20px 24px -4px ${baseTheme.palette.shadows.dark}`,
        border: `1px solid ${grey[400]}`,
        marginTop: baseTheme.spacing(0.5),
      },
    },
  },
};

const getAlertStyles = (severity: 'info' | 'error' | 'success' | 'warning') => ({
  backgroundColor: baseTheme.palette[severity].light,
  borderColor: baseTheme.palette[severity].main,
  color: baseTheme.palette[severity].dark,
});

const getButtonStyles = (color: 'primary' | 'secondary', variant: 'text' | 'outlined' | 'contained') => {
  switch (variant) {
    case 'outlined':
      return {
        borderColor: baseTheme.palette[color].light,
        color: baseTheme.palette[color].main,
      };
    default:
      return {};
  }
};

export const theme = createTheme(baseTheme, {
  mixins: {
    // App toolbar height should be controlled from here so useAppBarHeight can pick it up
    toolbar: {
      minHeight: 80,
      '@media (min-width:600px)': { minHeight: 80 },
      '@media (min-width:0px) and (orientation: landscape)': { minHeight: 80 },
    },
    modalStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxHeight: '75vh',
      display: 'flex',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius,
      border: 'none',
      outline: 'none',
      p: 3,
    },
    activeButton: {
      border: `2px solid ${baseTheme.palette.primary.main} !important`,
      boxShadow: `0px 0px 0px 4px ${baseTheme.palette.primary.light}`,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { fontFamily: baseTheme.typography.fontFamily },
      },
    },
    MuiModal: commonOverlayOverrides,
    MuiPopper: commonOverlayOverrides,
    MuiFormControl: {
      styleOverrides: {
        root: {
          gap: 2,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreIcon />,
      },
    },
    MuiPickersCalendarHeader: {
      defaultProps: {
        slots: {
          switchViewIcon: ExpandMoreIcon,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root + .MuiDivider-root': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: 'middle',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&, input, fieldset': {
            borderRadius,
          },
          '&:not(:is(:focus, .Mui-disabled)):hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.primary.light,
            borderWidth: '2px',
            textDecoration: 'none',
          },
          '&:is(.Mui-focused, :focus) fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: `${baseTheme.palette.primary.main} !important`,
            boxShadow: `0px 0px 0px 4px ${baseTheme.palette.primary.light}7A`,
          },
          '&:is(.Mui-disabled)': {
            backgroundColor: `${baseTheme.palette.grey[100]}`,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: `0px 1px 2px 0px ${baseTheme.palette.grey[300]}, 0px 1px 3px 0px ${baseTheme.palette.grey[200]}`,
          borderRadius,
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.grey[100],
          fontWeight: 500,
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            borderRadius,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderRadius,
          '&.MuiAlert-outlinedInfo': getAlertStyles('info'),
          '&.MuiAlert-outlinedError': getAlertStyles('error'),
          '&.MuiAlert-outlinedSuccess': getAlertStyles('success'),
          '&.MuiAlert-outlinedWarning': getAlertStyles('warning'),
          // Used to style alert content when it's an HTML string
          '& .MuiAlert-message *': {
            color: 'inherit',
            margin: 0,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: `0px 1px 2px 0px ${baseTheme.palette.grey[300]}, 0px 1px 3px 0px ${baseTheme.palette.grey[200]}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(1.5)}`,
          borderRadius,
          fontSize: '14px',
          '&.MuiButton-outlined:not(.Mui-disabled)': {
            '&.MuiButton-colorSecondary': getButtonStyles('secondary', 'outlined'),
            '&.MuiButton-colorPrimary': getButtonStyles('primary', 'outlined'),
          },
          // Used for buttons that render as a link
          '&.MuiButton-containedPrimary[href]:hover': { color: baseTheme.palette.primary.contrastText },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius,
        },
      },
    },
  },
} as ThemeOptions);
