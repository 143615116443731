import { Navigate, Params, Route, Routes } from 'react-router-dom';
import { PublicOnlyRoute } from 'app/PublicOnlyRoute';
import { PrivateRoute } from 'app/PrivateRoute';
import HasAccess from 'permissions/HasAccess';
import { Permission, UserRole } from 'permissions/constants/Roles';
import ProductsAccess from 'permissions/ProductsAccess';
import { ProductLevel } from 'permissions/constants/ProductLevels';
import React from 'react';
import { SchoolSettingsRoutes } from 'app/routes/SchoolSettingsRoutes';
import { SchoolEnquiriesRoutes } from 'app/routes/SchoolEnquiriesRoutes';
import { SchoolWorkflowRoutes } from 'app/routes/SchoolWorkflowRoutes';
import { ApplicantWorkflowRoutes } from 'app/routes/ApplicantWorkflowRoutes';
import { FakeApplicantAndApplicationProvider } from 'contexts/FakeApplicantAndApplicationContext';
import { WorkflowProvider } from '../../contexts/WorkflowContext';
import { AppLayout } from '../../components/AppLayout/AppLayout';
import { SubmitProvider } from 'components/Workflow/SubmitContext';

const Enquire = React.lazy(() => import(/* webpackChunkName: 'start' */ 'views/Enquiries/Enquire'));
const EnquireConfirm = React.lazy(() => import(/* webpackChunkName: 'start' */ 'views/Enquiries/EnquireConfirm'));
const Pipeline = React.lazy(() => import(/* webpackChunkName: 'pipeline' */ 'views/Pipeline/Pipeline'));
const Enquiry = React.lazy(() => import(/* webpackChunkName: 'leads' */ 'views/Enquiries/Enquiry'));
const Dashboard = React.lazy(() => import(/* webpackChunkName: 'dashboard' */ 'views/Dashboard/Dashboard'));
const ImportApplication = React.lazy(() => import(/* webpackChunkName: 'pipeline' */ 'views/ImportApplication/ImportApplication'));
const StudentManagement = React.lazy(() => import(/* webpackChunkName: 'pipeline' */ 'views/StudentManagement/StudentManagement'));
const AgentProfile = React.lazy(() => import(/* webpackChunkName: 'agents' */ 'views/AgentProfile/AgentProfile'));
const ApplicantsList = React.lazy(() => import(/* webpackChunkName: 'profile' */ 'views/Applicants/ApplicantsList'));
const CreateApplicant = React.lazy(() => import(/* webpackChunkName: 'profile' */ 'applicants/CreateApplicant'));
const Register = React.lazy(() => import(/* webpackChunkName: 'register' */ 'views/Auth/Register'));
const Login = React.lazy(() => import(/* webpackChunkName: 'login' */ 'views/Auth/Login'));
const RegisterVerification = React.lazy(() => import(/* webpackChunkName: 'register' */ 'views/Auth/RegisterVerification'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: 'errorpage' */ 'views/Error/ErrorPage'));
const PositionsTable = React.lazy(() => import(/* webpackChunkName: 'schooltables' */ 'views/schools/PositionsTable'));
const ParticipantsTable = React.lazy(() => import(/* webpackChunkName: 'schooltables' */ 'views/schools/ParticipantsTable'));
const HostOrgsTable = React.lazy(() => import(/* webpackChunkName: 'schooltables' */ 'views/schools/HostOrganisationsTable'));

export interface SchoolRouteParams extends Params {
  slug: string;
}

export interface AgentProfileParams extends SchoolRouteParams {
  agentId: string;
}

export interface EnquiryRouteParams extends SchoolRouteParams {
  id: string;
}

export const SchoolRoutes = () => (
  <WorkflowProvider>
    <SubmitProvider>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Navigate to="./enrolment-application" />} />
          <Route
            path="login"
            element={
              <PublicOnlyRoute>
                <Login />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="register"
            element={
              <PublicOnlyRoute>
                <Register />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="register/verify"
            element={
              <PublicOnlyRoute>
                <RegisterVerification />
              </PublicOnlyRoute>
            }
          />
          <Route
            path="participants"
            element={
              <PrivateRoute>
                <ParticipantsTable />
              </PrivateRoute>
            }
          />
          <Route
            path="positions"
            element={
              <PrivateRoute>
                <PositionsTable />
              </PrivateRoute>
            }
          />
          <Route
            path="host-orgs"
            element={
              <PrivateRoute>
                <HostOrgsTable />
              </PrivateRoute>
            }
          />
          <Route path="enquire" element={<Enquire />} />
          <Route path="enquire/confirm" element={<EnquireConfirm />} />
          <Route
            path="pipeline/*"
            element={
              <PrivateRoute>
                <ApplicantWorkflowRoutes />
              </PrivateRoute>
            }
          />
          <Route
            path="archive"
            element={
              <PrivateRoute>
                <HasAccess for="permission" name={[Permission.ViewPipeline]} fallback={<ErrorPage />}>
                  <Pipeline archive />
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route
            path="applicants"
            element={
              <PrivateRoute>
                <ApplicantsList />
              </PrivateRoute>
            }
          />
          <Route
            path="applicants/new"
            element={
              <PrivateRoute>
                <CreateApplicant />
              </PrivateRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <HasAccess for="permission" name={[Permission.ViewDashboard]} fallback={<ErrorPage />}>
                  <ProductsAccess disallow={[ProductLevel.EnrollerRecruit]}>
                    <Dashboard />
                  </ProductsAccess>
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route
            path="studentsummary"
            element={
              <PrivateRoute>
                <HasAccess for="permission" name={[Permission.ViewPipeline]} fallback={<ErrorPage />}>
                  <StudentManagement />
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route
            path="importapplication"
            element={
              <PrivateRoute>
                <HasAccess for="permission" name={[Permission.ImportApplication]} fallback={<ErrorPage />}>
                  <FakeApplicantAndApplicationProvider>
                    <ImportApplication />
                  </FakeApplicantAndApplicationProvider>
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route
            path="enquiry/:id"
            element={
              <PrivateRoute>
                <HasAccess for="permission" name={[Permission.EditLead]} fallback={<ErrorPage />}>
                  <Enquiry />
                </HasAccess>
              </PrivateRoute>
            }
          />
          <Route path="enquiries/*" element={<SchoolEnquiriesRoutes />} />
          <Route path="settings/*" element={<SchoolSettingsRoutes />} />
          <Route
            path="agent-profile/:agentId"
            element={
              <PrivateRoute>
                <HasAccess for="role" name={[UserRole.InstitutionalStaff, UserRole.InstitutionalAdmin]} fallback={<ErrorPage />}>
                  <AgentProfile />
                </HasAccess>
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="enrolment-application/*" element={<SchoolWorkflowRoutes />} />
      </Routes>
    </SubmitProvider>
  </WorkflowProvider>
);
