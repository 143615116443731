import { ApiParticipant } from '../../types/api/ApiParticipant';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { ParticipantResume } from './ParticipantResume';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DatePickerField from '../DatePickerField';
import TimeField from '../TimeField';
import TimezoneField from '../TimezoneField';
import { usePut } from '../../../../hooks/usePut';
import { ResponseEnvelope } from '../../../../types/ResponseEnvelope';
import { useNotificationMessages } from '../../../../hooks/useNotificationMessages';
import { getErrorMessage } from '../../../../utils/errors';
import { LoadingButton } from '@mui/lab';
import { usePost } from '../../../../hooks/usePost';
import { format } from 'date-fns';
import { useCountries } from '../../../../contexts/CountriesContext';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

interface ScheduleInterviewParticipantProps {
  participant: ApiParticipant;
  sendInterviewDetailsUrl: string;
  acceptParticipantUrl: string;
}

export const ScheduleInterviewParticipant = ({ participant, sendInterviewDetailsUrl, acceptParticipantUrl }: ScheduleInterviewParticipantProps) => {
  const theme = useTheme();
  const [showSection, setShowSection] = useState<'resume' | 'interview' | 'none'>('none');
  const [scheduleMethod, setScheduleMethod] = useState<'calendar-link' | 'manual-dates'>('calendar-link');
  const [calendarLink, setCalendarLink] = useState('');
  const [interviewDate1, setInterviewDate1] = useState<Date | undefined>(undefined);
  const [interviewTime1, setInterviewTime1] = useState<Date | undefined>(undefined);
  const [interviewTimezone1, setInterviewTimezone1] = useState<string | null>(null);
  const [interviewDate2, setInterviewDate2] = useState<Date | undefined>(undefined);
  const [interviewTime2, setInterviewTime2] = useState<Date | undefined>(undefined);
  const [interviewTimezone2, setInterviewTimezone2] = useState<string | null>(null);
  const [interviewDate3, setInterviewDate3] = useState<Date | undefined>(undefined);
  const [interviewTime3, setInterviewTime3] = useState<Date | undefined>(undefined);
  const [interviewTimezone3, setInterviewTimezone3] = useState<string | null>(null);
  const [loadingInterview, putInterview] = usePut<ResponseEnvelope<any>>(sendInterviewDetailsUrl);
  const [loadingAccept, postAccept] = usePost<ResponseEnvelope<any>>(acceptParticipantUrl);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();
  const { countryMap } = useCountries();

  const interviewButtonStyles = showSection === 'interview' ? theme.mixins.activeButton : {};
  const participantCountry = useMemo(() => countryMap.get(participant.applicant.country_id), [countryMap, participant.applicant.country_id]);

  const formatInterviewDetails = useCallback((): { startDate: string }[] => {
    const interviewSlots: string[] = [];
    if (interviewDate1 && interviewTime1 && interviewTimezone1) {
      interviewSlots.push([format(interviewDate1, 'yyyy-MM-dd'), format(interviewTime1, 'HH:mm'), interviewTimezone1].join(','));
    }
    if (interviewDate2 && interviewTime2 && interviewTimezone2) {
      interviewSlots.push([format(interviewDate2, 'yyyy-MM-dd'), format(interviewTime2, 'HH:mm'), interviewTimezone2].join(','));
    }
    if (interviewDate3 && interviewTime3 && interviewTimezone3) {
      interviewSlots.push([format(interviewDate3, 'yyyy-MM-dd'), format(interviewTime3, 'HH:mm'), interviewTimezone3].join(','));
    }
    return interviewSlots.map((i) => ({ startDate: i }));
  }, [
    interviewDate1,
    interviewDate2,
    interviewDate3,
    interviewTime1,
    interviewTime2,
    interviewTime3,
    interviewTimezone1,
    interviewTimezone2,
    interviewTimezone3,
  ]);

  const sendInterviewDetails = useCallback(async () => {
    const payload: { paxApplicationId: string; interviewUrl?: string; interviewDates?: { startDate: string }[] } = { paxApplicationId: participant.id };
    if (scheduleMethod === 'calendar-link') {
      payload.interviewUrl = calendarLink;
    } else {
      payload.interviewDates = formatInterviewDetails();
    }
    try {
      const response = await putInterview(payload);
      showSuccessMessage(response.message);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [calendarLink, formatInterviewDetails, participant.id, putInterview, scheduleMethod, showErrorMessage, showSuccessMessage]);

  const acceptParticipant = useCallback(async () => {
    // accept endpoint for some reason needs interview details
    const payload: { paxApplicationId: string; interviewUrl?: string; interviewDates?: { startDate: string }[] } = { paxApplicationId: participant.id };
    if (scheduleMethod === 'calendar-link') {
      payload.interviewUrl = calendarLink;
    } else {
      payload.interviewDates = formatInterviewDetails();
    }
    try {
      const response = await postAccept(payload);
      showSuccessMessage(response.message);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [calendarLink, formatInterviewDetails, participant.id, postAccept, scheduleMethod, showErrorMessage, showSuccessMessage]);

  const toggleResume = () => {
    setShowSection(showSection === 'resume' ? 'none' : 'resume');
  };

  const toggleInterview = () => {
    setShowSection(showSection === 'interview' ? 'none' : 'interview');
  };

  return (
    <Stack direction="column" sx={{ border: '1px solid #eee', m: 1, p: 2 }} spacing={2}>
      <Stack direction="column" flex={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" component="h2" color="primary" textTransform="capitalize">
            {participant.applicant.first_name} {participant.applicant.last_name}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={toggleResume}>
              {showSection === 'resume' ? 'Hide' : 'View'} resume
            </Button>
            <Button variant="outlined" onClick={toggleInterview} sx={interviewButtonStyles}>
              Schedule interview
            </Button>
            <LoadingButton variant="contained" loading={loadingAccept} onClick={acceptParticipant}>
              Accept
            </LoadingButton>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          {participantCountry && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                src={`/images/flag-icons/flags/4x3/${participantCountry.iso_3166_2.toLowerCase()}.svg`}
                variant="rounded"
                sx={{ width: 20, height: 20, fontSize: '14px' }}
              >
                {participantCountry.iso_3166_2}
              </Avatar>
              <Typography color="secondary" variant="subtitle2">
                {participantCountry.name}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={0.5} alignItems="center">
            <EmailOutlinedIcon color="secondary" />
            <Typography color="secondary" variant="subtitle2">
              {participant.applicant.email}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {showSection !== 'none' && (
        <Box sx={{ px: 2 }}>
          <Divider />
        </Box>
      )}
      {showSection === 'resume' && <ParticipantResume participant={participant} />}
      {showSection === 'interview' && (
        <Stack direction="column" spacing={2}>
          <Alert severity="info" variant="outlined">
            <AlertTitle>Interview Scheduling</AlertTitle>
            Please select whether to share your calendar link with the participant, or whether to send the participant applicable time slots for you to schedule
            a meeting later. To learn how to share a calendar link, click on your meeting software’s name:{' '}
            <Link rel="noopener" target="_blank" sx={{ textDecoration: 'underline' }} href="https://support.google.com/calendar/answer/10729749">
              Google
            </Link>{' '}
            |{' '}
            <Link
              rel="noopener"
              target="_blank"
              sx={{ textDecoration: 'underline' }}
              href="https://learn.microsoft.com/en-us/microsoft-365/bookings/customize-booking-page?view=o365-worldwide"
            >
              Microsoft Booking
            </Link>{' '}
            |{' '}
            <Link
              rel="noopener"
              target="_blank"
              sx={{ textDecoration: 'underline' }}
              href="https://help.calendly.com/hc/en-us/articles/23343506477463-The-perfect-scheduling-setup#01H8PG9T19N2DKQ5Y9MTHPKB4F"
            >
              Calendly
            </Link>
          </Alert>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Interview scheduling method</FormLabel>
            <RadioGroup row value={scheduleMethod}>
              <FormControlLabel value="calendar-link" control={<Radio />} label="Share calendar link" onClick={() => setScheduleMethod('calendar-link')} />
              <FormControlLabel
                value="manual-dates"
                control={<Radio />}
                label="Manual interview scheduling"
                onClick={() => setScheduleMethod('manual-dates')}
              />
            </RadioGroup>
          </FormControl>
          {scheduleMethod === 'calendar-link' && (
            <Grid container justifyContent="center" spacing={3}>
              <Grid xs={12} sx={{ px: 0 }}>
                <FormControl fullWidth size="small">
                  <FormLabel id="scheduling-link-label">Scheduling link</FormLabel>
                  <TextField
                    size="small"
                    value={calendarLink}
                    onChange={(event) => setCalendarLink(event.target.value)}
                    aria-labelledby="scheduling-link-label"
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sx={{ px: 0 }}>
                <LoadingButton variant="contained" loading={loadingInterview} onClick={sendInterviewDetails}>
                  Send interview request
                </LoadingButton>
              </Grid>
            </Grid>
          )}
          {scheduleMethod === 'manual-dates' && (
            <Grid container justifyContent="center">
              <Grid xs={12} lg={4}>
                <DatePickerField
                  name="interview-date-1"
                  label="Date"
                  value={interviewDate1}
                  onChange={(newValue) => setInterviewDate1(newValue as Date)}
                  disabled={false}
                  readOnly={false}
                />
              </Grid>
              <Grid xs={12} lg={4} sx={{ px: 3 }}>
                <TimeField
                  label="Time"
                  value={interviewTime1}
                  onChange={(newValue) => setInterviewTime1(newValue as Date)}
                  name="interview-time-1"
                  readOnly={false}
                  disabled={false}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <TimezoneField
                  label="Timezone"
                  value={interviewTimezone1}
                  onChange={(newValue) => setInterviewTimezone1(newValue)}
                  name="interview-timezone-1"
                  readOnly={false}
                  disabled={false}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <DatePickerField
                  name="interview-date-2"
                  label="Date"
                  value={interviewDate2}
                  onChange={(newValue) => setInterviewDate2(newValue as Date)}
                  disabled={false}
                  readOnly={false}
                />
              </Grid>
              <Grid xs={12} lg={4} sx={{ px: 3 }}>
                <TimeField
                  label="Time"
                  value={interviewTime2}
                  onChange={(newValue) => setInterviewTime2(newValue as Date)}
                  name="interview-time-2"
                  readOnly={false}
                  disabled={false}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <TimezoneField
                  label="Timezone"
                  value={interviewTimezone2}
                  onChange={(newValue) => setInterviewTimezone2(newValue)}
                  name="interview-timezone-2"
                  readOnly={false}
                  disabled={false}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <DatePickerField
                  name="interview-date-3"
                  label="Date"
                  value={interviewDate3}
                  onChange={(newValue) => setInterviewDate3(newValue as Date)}
                  disabled={false}
                  readOnly={false}
                />
              </Grid>
              <Grid xs={12} lg={4} sx={{ px: 3 }}>
                <TimeField
                  label="Time"
                  value={interviewTime3}
                  onChange={(newValue) => setInterviewTime3(newValue as Date)}
                  name="interview-time-3"
                  readOnly={false}
                  disabled={false}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <TimezoneField
                  label="Timezone"
                  value={interviewTimezone3}
                  onChange={(newValue) => setInterviewTimezone3(newValue)}
                  name="interview-timezone-3"
                  readOnly={false}
                  disabled={false}
                />
              </Grid>
              <Grid xs={12} sx={{ pt: 3 }}>
                <LoadingButton variant="contained" disabled={formatInterviewDetails().length === 0} loading={loadingInterview} onClick={sendInterviewDetails}>
                  Send interview request
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Stack>
      )}
    </Stack>
  );
};
