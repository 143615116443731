import * as types from 'app/constants/ActionTypes';

export const institutionUsersInitialState = {
  status: 'default',
  users: [],
  editStatus: 'default',
  editMessage: null,
  addStatus: 'default',
  addMessage: null,
  deleteStatus: 'default',
  deleteMessage: null,
  enableStatus: 'default',
  searchStatus: 'default',
  sortField: 'name',
  sortOrder: 'asc',
  userDetails: null,
  currentName: '',
};

export const institutionUsersReducer = (state = institutionUsersInitialState, action) => {
  switch (action.type) {
    case types.INSTITUTION_USERS_GET:
      return {
        ...state,
        deleteStatus: 'default',
        editStatus: 'default',
        enableStatus: 'default',
        status: 'loading',
      };
    case types.INSTITUTION_USERS_GET_RESPONSE: {
      const users = action.payload.users;

      return {
        ...state,
        status: 'default',
        users,
      };
    }
    case types.INSTITUTION_USERS_TOGGLE_STATUS: {
      const status = { ...state };

      if (action.method === 'disable') {
        status.deleteStatus = 'loading';
        status.enableStatus = 'default';
      } else {
        status.enableStatus = 'loading';
        status.deleteStatus = 'default';
      }

      return {
        ...state,
        ...status,
      };
    }
    case types.INSTITUTION_USERS_TOGGLE_STATUS_RESPONSE: {
      const toggleState = { ...state };

      if (action.payload.success) {
        if (action.payload.method === 'disable') {
          toggleState.deleteStatus = 'success';
        } else {
          toggleState.enableStatus = 'success';
        }
      }

      return {
        ...state,
        ...toggleState,
      };
    }
    case types.INSTITUTION_USERS_SEARCH:
      return {
        ...state,
        status: 'updating',
        searchStatus: 'loading',
      };
    case types.INSTITUTION_USERS_SEARCH_RESPONSE: {
      const searchableLeads = state.users.slice();

      const searchTerms = action.payload.searchTerm.split(' ');

      const results = searchableLeads.filter((lead) => {
        return searchTerms.some((term) => {
          const regex = new RegExp(`^(${term})$`, 'i');

          // @ts-expect-error TS(2339): Property 'first_name' does not exist on type 'neve... Remove this comment to see the full error message
          return regex.test(lead.first_name) || regex.test(lead.last_name);
        });
      });

      return {
        ...state,
        users: results,
        status: 'default',
        searchStatus: 'default',
      };
    }
    case types.INSTITUTION_USERS_SORT:
      return {
        ...state,
        sortField: action.field,
        sortOrder: action.order,
      };
    default:
      return {
        ...state,
      };
  }
};
