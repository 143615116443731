import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useParams } from 'react-router-dom';
import { AppContext } from 'app/AppContext';
import Loader from 'components/Loader';
import paymentProviderMap from 'payment/paymentProviderMap';
import { invoiceBalanceDue, invoiceBalanceDueInCents, hasBalanceDue as hasBalanceDueSelector, latestPaymentId } from 'invoices/invoiceSelectors';
import { BANK_PAYMENT_PROVIDER } from 'payment/PaymentProviders';
import { getPaymentProviders } from 'payment/paymentActions';
import { getInvoiceById } from 'invoices/invoiceActions';
import { useMountEffect } from 'hooks/useMountEffect';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Stack } from '@mui/material';
import { useLocale } from 'contexts/LocaleContext';
import { useSchool } from 'contexts/SchoolContext';

export const Payment = ({ selectedPaymentMethod, invoiceId, sharedPage = false }) => {
  const appContext = useContext(AppContext);
  const [fetchedProviders, setFetchedProviders] = useState(false);
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const {
    state: { school },
  } = useSchool();
  const { locale } = useLocale();
  const currencyCode = school?.currency_code || 'NZD';
  const dispatch = useAppDispatch();
  const applicationId = useAppSelector((state) => state.invoice.applicationId);
  const paymentProvidersFetchStatus = useAppSelector((state) => state.payment.status);
  const invoiceFetchStatus = useAppSelector((state) => state.invoice.fetchStatus);
  const paymentProviders = useAppSelector((state) => state.payment.paymentProviders);
  const invoiceNumber = useAppSelector((state) => state.invoice.invoiceNumber);
  const applicantCountry = useAppSelector((state) => state.invoice.student.country);
  const balanceDue = useAppSelector((state) => invoiceBalanceDue(state, locale, currencyCode));
  const balanceDueInCents = useAppSelector((state) => invoiceBalanceDueInCents(state, locale, currencyCode));
  const hasBalanceDue = useAppSelector((state) => hasBalanceDueSelector(state, locale, currencyCode) as any);
  const paymentId = useAppSelector(latestPaymentId as any);

  useMountEffect(() => {
    dispatch(getInvoiceById(invoiceId, schoolSlug));
  });

  useEffect(() => {
    if (applicationId) {
      dispatch(getPaymentProviders(applicationId, schoolSlug));
      setFetchedProviders(true);
    }
  }, [dispatch, applicationId, schoolSlug]);

  if (
    paymentProvidersFetchStatus === 'loading' ||
    invoiceFetchStatus === 'loading' ||
    !paymentProviders ||
    !paymentProviders.length ||
    !invoiceId ||
    !fetchedProviders
  ) {
    return (
      <Stack>
        <Loader />
      </Stack>
    );
  }

  if (!hasBalanceDue) {
    return <p>The invoice has already been paid.</p>;
  }

  const selectedPaymentProvider = selectedPaymentMethod
    ? paymentProviders.find((paymentProvider) => paymentProvider.slug === selectedPaymentMethod)
    : paymentProviders[0];

  const paymentType = selectedPaymentProvider.slug || BANK_PAYMENT_PROVIDER;
  const PaymentComponent = paymentProviderMap[paymentType].paymentComponent;

  return (
    <PaymentComponent
      amount={balanceDue}
      amountInCents={balanceDueInCents}
      backURL={sharedPage ? `/${schoolSlug}/student-invoice/${invoiceId}` : `/${schoolSlug}/pipeline`}
      country={applicantCountry && applicantCountry.iso_3166_2}
      headers={appContext.headers}
      invoiceId={invoiceId}
      invoiceNumber={invoiceNumber}
      locale={locale}
      paymentId={paymentId}
      selectedPaymentProviderId={selectedPaymentProvider.id}
      sharedPayment={sharedPage}
      slug={schoolSlug}
      workflowConfigurations={selectedPaymentProvider.configurations || []}
      {...selectedPaymentProvider.config}
    />
  );
};
