import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from 'app/AppContext';
import { useStatelessPost } from 'hooks/useStatelessPost';
import LoadingButton from '@mui/lab/LoadingButton';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { ButtonProps, MenuItem } from '@mui/material';

interface ReEnrolProps {
  actionId: string;
  workflowEndpoint: string;
  label: string;
  disabled?: boolean;
  color: ButtonProps['color'];
  variant: ButtonProps['variant'];
}

export const ReEnrol = ({ actionId, workflowEndpoint, label, disabled, variant, color }: ReEnrolProps) => {
  const navigate = useNavigate();
  const params = useParams() as SchoolRouteParams;
  const postReEnrol = useStatelessPost<ResponseEnvelope<any>>(`${workflowEndpoint}/actions/${actionId}`);
  const [loadingReEnrol, setLoadingReEnrol] = useState(false);
  const appContext = useContext(AppContext);

  const reEnrolApplicant = async () => {
    setLoadingReEnrol(true);

    try {
      const response = await postReEnrol({});
      const {
        success,
        data: { application },
        message,
      } = response;
      const url = `/${params.slug}/enrolment-application/applicant/${application.applicant.id}/application/${application.id}`;

      if (success) {
        appContext.operations.onSuccess(message);
        const stage = application.current_stage;
        const step = application.current_step;

        if (stage && step) {
          navigate(`${url}/stages/${stage.slug}/steps/${step.slug}`);
        }
      } else {
        appContext.operations.onError(response.message);
      }
    } catch (error) {
      appContext.operations.onError(error);
    } finally {
      setLoadingReEnrol(false);
    }
  };

  // Used for table actions
  if (variant === 'text') return <MenuItem onClick={reEnrolApplicant}>{label}</MenuItem>;

  return (
    <LoadingButton variant={variant} color={color} loading={loadingReEnrol} disabled={disabled} onClick={reEnrolApplicant}>
      {label}
    </LoadingButton>
  );
};
