import { useMemo } from 'react';
import TIMEZONES from 'app/constants/timezones';
import { FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';

interface TimezoneFieldProps {
  label: string;
  disabled: boolean;
  value: string | null;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
}

export const TimezoneField = ({ label, disabled, value, onChange, error, name, readOnly }: TimezoneFieldProps) => {
  const timezoneMap = useMemo(() => {
    const map = new Map();
    TIMEZONES.forEach((t) => map.set(t.name, t));
    return map;
  }, []);
  const source = useMemo(
    () =>
      TIMEZONES.map((timezone) => timezone.name).sort((a, b) => {
        const x = a.toLowerCase();
        const y = b.toLowerCase();

        return x < y ? -1 : x > y ? 1 : 0;
      }),
    [],
  );

  return (
    <>
      <FormControl fullWidth size="small" data-cy-field-type="timezone">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <Autocomplete
          aria-labelledby={`${name}-label`}
          options={source}
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
          renderInput={(params) => {
            return <TextField {...params} size="small" error={!!error} placeholder="Select timezone" />;
          }}
          getOptionLabel={(t) => {
            const tz = timezoneMap.get(t);
            return tz ? `${tz.name} ${tz.offset}` : '';
          }}
          disabled={disabled}
          readOnly={readOnly}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default TimezoneField;
