import { useMemo } from 'react';
import { Autocomplete, FormControl, FormLabel, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';

interface SchoolSubjectsSelectorFieldProps {
  label: string;
  disabled: boolean;
  value: string[];
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  options: { label: string; value: string }[];
  subjects: Record<string, any>[];
}

export const SchoolSubjectsSelectorField = ({
  label,
  disabled,
  value,
  onChange,
  error,
  name,
  readOnly,
  options,
  subjects,
}: SchoolSubjectsSelectorFieldProps) => {
  const subjectsMap: Map<string, string> = useMemo(
    () =>
      (subjects || []).sort((a, b) => a.name.localeCompare(b.name)).reduce<Map<string, string>>((map, subject) => map.set(subject.id, subject.name), new Map()),
    [subjects],
  );
  const maxOptions = Number(options[0]) || Number.MAX_VALUE;
  return (
    <>
      <FormControl fullWidth data-cy-field-type="school-subjects-selector">
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <Autocomplete
          multiple
          disablePortal
          disabled={disabled}
          aria-labelledby={`${name}-label`}
          size="small"
          value={value || []}
          options={Array.from(subjectsMap.keys())}
          getOptionLabel={(subjectId) => subjectsMap.get(subjectId)!}
          renderOption={(props, subjectId) => (
            <MenuItem {...props} disabled={value.length === maxOptions && !value.includes(subjectId)}>
              {subjectsMap.get(subjectId)}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              error={!!error}
              placeholder="Search subjects..."
            />
          )}
          onChange={(event, val) => onChange(val)}
          readOnly={readOnly}
        />
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default SchoolSubjectsSelectorField;
