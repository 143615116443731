// eslint-disable-next-line
import packageJson from '../../package.json';
import { env, EnvType } from 'utils/env';
import { BrandingOrganisation } from 'branding/branding.interface';

export interface AppConfig {
  environment: 'local' | 'development' | 'uat' | 'production';
  version: string;
  pageTitle: string;
  apiUrl: string;
  appBrandTheme: BrandingOrganisation;
  enrolmentsUrl: string;
  marketplaceUrl: string;
  agenciesUrl: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0CallbackUrl: string;
  auth0Audience: string;
  auth0Organisation?: string;
  googleMapsApiKey: string;
  intercomAppId?: string;
  sentryDsn?: string;
  googleAnalyticsId?: string;
  logRocketId?: string;
}

const throwIfUndefined = (envVar: keyof EnvType): string => {
  if (env[envVar] === undefined) {
    throw new Error(`${String(envVar)} is not defined, cannot load application config`);
  }

  return env[envVar]!;
};

let config: AppConfig;

export const getConfig = (): AppConfig => {
  if (config) return config;
  let deploymentTarget = throwIfUndefined('REACT_APP_ENV');
  if (deploymentTarget !== 'local' && deploymentTarget !== 'development' && deploymentTarget !== 'production') {
    deploymentTarget = 'production';
  }

  config = {
    environment: deploymentTarget as 'local' | 'development' | 'production',
    version: packageJson.version.trim(),
    pageTitle: throwIfUndefined('REACT_APP_PAGE_TITLE'),
    apiUrl: throwIfUndefined('REACT_APP_API_URL'),
    appBrandTheme: throwIfUndefined('REACT_APP_BRAND_THEMING') as BrandingOrganisation,
    enrolmentsUrl: throwIfUndefined('REACT_APP_URL'),
    marketplaceUrl: throwIfUndefined('REACT_APP_MARKETING_URL'),
    agenciesUrl: throwIfUndefined('REACT_APP_AGENCIES_URL'),
    auth0Domain: throwIfUndefined('REACT_APP_AUTH0_DOMAIN'),
    auth0ClientId: throwIfUndefined('REACT_APP_AUTH0_CLIENT_ID'),
    auth0CallbackUrl: throwIfUndefined('REACT_APP_AUTH0_CALLBACK_URL'),
    auth0Audience: throwIfUndefined('REACT_APP_AUTH0_AUDIENCE'),
    // TODO: Auth0 org when we need it
    auth0Organisation: undefined,
    googleMapsApiKey: throwIfUndefined('REACT_APP_GOOGLE_PLACES_API_KEY'),
    intercomAppId: env['REACT_APP_INTERCOM_APP_ID'],
    sentryDsn: env['REACT_APP_SENTRY_DSN'],
    googleAnalyticsId: env['REACT_APP_GOOGLE_ANALYTICS_ID'],
    logRocketId: env['REACT_APP_LOGROCKET_ID'],
  };
  return config;
};
