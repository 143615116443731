import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { slugify } from 'utils/slugify';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

interface RadioFieldProps {
  label: string;
  disabled: boolean;
  value?: string;
  onChange: ControllerRenderProps['onChange'];
  error?: FieldError;
  name: string;
  readOnly: boolean;
  options: { label: string; value: string }[];
  direction: 'horizontal' | 'vertical';
}

export const RadioField = ({ label, disabled, value, onChange, error, name, readOnly, options, direction }: RadioFieldProps) => {
  return (
    <>
      <FormControl data-cy-field-type="radio" sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}>
        <FormLabel id={`${name}-label`}>{label}</FormLabel>
        <RadioGroup aria-labelledby={`${name}-label`} value={value} onChange={onChange}>
          <Stack direction={direction === 'horizontal' ? 'row' : 'column'} flexWrap="wrap" columnGap={3} rowGap={1}>
            {options.map((option) => {
              const valueSlug = slugify(option.value);

              return (
                <FormControlLabel
                  data-cy={`${name}-radio-${valueSlug}`}
                  key={`${name}-radio-${valueSlug}`}
                  disabled={disabled}
                  value={option.value}
                  control={<Radio />}
                  checked={value === option.value}
                  label={option.value}
                />
              );
            })}
          </Stack>
        </RadioGroup>
      </FormControl>
      {error ? <Typography color="error">{error!.message as string}</Typography> : <br />}
    </>
  );
};

// for React.lazy
export default RadioField;
